import {AnyPcbNode, IRouteData, IDocumentNet} from "@buildwithflux/core";
import {IElementData, IPropertyData} from "@buildwithflux/models";
import {useMemo} from "react";

import type {IApplicationState} from "../../../../state";
import {pickValues} from "@buildwithflux/shared";
import {isEqual} from "lodash";

export interface ISelectedItem {
    uid: string;
    subjectData?: IRouteData | IElementData | AnyPcbNode | IDocumentNet;
    type: "elements" | "routes" | "document" | "nodes" | "nets";
}

export type PropertySpecialState = Omit<IPropertyData, "uid"> & {state: "mixed" | "multiple"};

export type MixedOrSingleProperty = IPropertyData | PropertySpecialState;

export function getPropertyKey(property: MixedOrSingleProperty) {
    return "state" in property ? property.name : property.uid;
}

// QUESTION: how is the perf of this selector? it looks like it could be bad
// TODO: this selector really needs refactoring and testing
// NOTE: needs to be used with shallow comparison for proper memoization
const useCurrentSubject = (selectedSubjectsUids: string[], isPCB: boolean) => {
    return useMemo(
        () =>
            (state: IApplicationState): ISelectedItem => {
                const documentUid = state.document?.uid;
                const selectedItems = {
                    elements: pickValues(state.document?.elements ?? {}, selectedSubjectsUids),
                    nets: pickValues(state.document?.nets ?? {}, selectedSubjectsUids),
                    nodes: [] as AnyPcbNode[],
                };
                if (isPCB) {
                    selectedItems.nodes = pickValues(state.document?.pcbLayoutNodes ?? {}, selectedSubjectsUids);
                }
                for (const type of ["elements", "nodes", "nets"] as (keyof typeof selectedItems)[]) {
                    const item = selectedItems[type]?.[0];
                    if (item) {
                        return {uid: item.uid, subjectData: item, type};
                    }
                }
                return {uid: documentUid || "", type: "document"};
            },
        [selectedSubjectsUids, isPCB],
    );
};

// NOTE: needs to be used with shallow comparison for proper memoization
const useSelectedSubjectProperties = (currentSubject: ISelectedItem, selectedSubjectsUids: string[]) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const documentProperties = state.document?.properties;
            if (documentProperties && selectedSubjectsUids.length === 0) {
                return documentProperties;
            }

            const elements = pickValues(state.document?.elements ?? {}, selectedSubjectsUids);
            const routes = pickValues(state.document?.routes ?? {}, selectedSubjectsUids);
            const nets = pickValues(state.document?.nets ?? {}, selectedSubjectsUids);

            // Merge the properties of elements, routes and nets and exclude any properties that do not exist on the current subject
            const mergedProperties: {[uid: string]: MixedOrSingleProperty} = {};
            let firstIteration = true;
            const mergeProperties = (subject: IElementData | IDocumentNet | IRouteData): void => {
                if (!subject.properties) return;

                const subjectPropertyNames = new Set<string>();
                Object.values(subject.properties).forEach((property) => {
                    if (firstIteration) {
                        mergedProperties[property.name] = property;
                    } else if (mergedProperties[property.name]) {
                        const multiProperty = {...mergedProperties[property.name]!} as PropertySpecialState;
                        mergedProperties[property.name] = multiProperty;
                        // cleans up the uid so that it doesn't have the shape of a single property, and we can't assign it to the wrong type
                        if ("uid" in multiProperty) {
                            delete multiProperty.uid;
                        }
                        if (!(("state" in multiProperty) as any)) {
                            multiProperty.state = "multiple";
                        }
                        if (!isEqual(multiProperty.value, property.value)) {
                            multiProperty.value = "";
                            multiProperty.state = "mixed";
                        }
                    }
                    subjectPropertyNames.add(property.name);
                });

                // Remove any properties that do not exist on all subjects
                // (`firstIteration` check above ensures each property is only added once, and not re-added after removal)
                Object.keys(mergedProperties).forEach((key) => {
                    if (!subjectPropertyNames.has(key)) {
                        delete mergedProperties[key];
                    }
                });

                firstIteration = false;
            };
            elements.forEach(mergeProperties);
            nets.forEach(mergeProperties);
            routes.forEach(mergeProperties);

            return mergedProperties;
        },
        [currentSubject, selectedSubjectsUids],
    );
};

const documentCommonSelectors = {
    useCurrentSubject,
    useSelectedSubjectProperties,
};

export default documentCommonSelectors;
