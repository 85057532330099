import {createActionRecordAction} from "../../../../../helpers/actionCreator";

type NetId = string;
type NetLabel = string;
export type NetLabelMap = Record<NetId, NetLabel>; // For clarity

export const updateNetsLabel = createActionRecordAction(
    "updateNetsLabel",
    (netLabelMap: NetLabelMap, canUndo = true) => {
        return {
            payload: {
                netLabelMap,
                canUndo: canUndo,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "updated the label of a net",
);
