import {IProblem} from "@buildwithflux/models";
import {produce} from "immer";
import {create} from "zustand";

import {IDrcValidator} from "./../../drc_validator/types";

export interface IProblemsStore {
    detectedProblems: Record<string, IProblem>;

    // Errors that occured during DRC computation (as in, the DRC service failed to compute the DRC)
    detectedErrors: Record<string, string>;

    validators: IDrcValidator[];

    updateDrcResults: (
        detectedProblems: Record<string, IProblem>,
        detectedErrors: Record<string, string>,
        validators: IDrcValidator[],
    ) => void;
}

export const useProblemsStore = create<IProblemsStore>()((set) => ({
    detectedProblems: {},
    detectedErrors: {},
    processedData: null,
    validators: [],
    updateDrcResults(detectedProblems, detectedErrors, validators) {
        set(
            produce((state: IProblemsStore): void => {
                state.detectedProblems = detectedProblems;
                state.detectedErrors = detectedErrors;
                state.validators = validators;
            }),
        );
    },
}));

export function useActiveProblemsCount() {
    return useProblemsStore((s) => Object.keys(s.detectedProblems).length + Object.keys(s.detectedErrors).length);
}

export function useProblems() {
    return useProblemsStore((s) => s.detectedProblems);
}

export function useProblem(key: string) {
    return useProblemsStore((s) => s.detectedProblems[key]);
}

export function useErrors() {
    return useProblemsStore((s) => s.detectedErrors);
}
