import {PcbBakedNode, PcbNodeTypes} from "@buildwithflux/models";
import {DocumentService} from "@buildwithflux/solder-core";

// TODO: consider moving this module to new file: frontend/src/helpers/pcb.ts

export function getAllBakedNodes(documentService: DocumentService) {
    return documentService.snapshot().pcbLayoutNodes;
}

export function getBakedNode(documentService: DocumentService, nodeUid: string) {
    const {pcbLayoutNodes} = documentService.snapshot();

    return pcbLayoutNodes[nodeUid];
}

export function getTypedBakedNode<T extends PcbNodeTypes>(documentService: DocumentService, nodeUid: string, type: T) {
    const node = getBakedNode(documentService, nodeUid);

    if (node?.type === type) {
        return node as PcbBakedNode<T>;
    } else {
        return undefined;
    }
}
