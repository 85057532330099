import {pcbEditorBehaviors} from "@buildwithflux/constants";
import {IPcbBoardLayerBase, LayerOrientation, PcbBoardLayerMaterials, PcbBoardLayerType} from "@buildwithflux/core";
import {getLayerIdentifier, isCopperLayer, isCopperMaterial, matchLayerIdentifier} from "@buildwithflux/models";
import {useCallback} from "react";

import {PcbEditorUiStore} from "../../../../modules/stores/pcb/PcbEditorUiStore";
import {PcbEditorUiState} from "../../../../modules/stores/pcb/PcbEditorUiStore.types";
import {IPcbLayerViewStore} from "../../../../modules/stores/pcb/PcbLayerViewStore";
import {IPcbUiSelectStore} from "../../../../modules/stores/pcb/PcbUiSelectStore";

const useNetRoutingState = (netId: string) => {
    return useCallback(
        (state: PcbEditorUiStore) => (state.routingState?.netId === netId ? state.routingState : null),
        [netId],
    );
};

const useFocusedNodeByType = (
    layer: string | undefined,
    type: PcbBoardLayerType,
    topLevelLayoutUid?: string,
    topLevelFootprintUid?: string,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            if (state.focussedLayer === undefined) return;
            const activeRootLayoutUid = state.activeLayoutUid;
            if (
                activeRootLayoutUid !== topLevelLayoutUid &&
                activeRootLayoutUid !== topLevelFootprintUid &&
                type !== "Metadata"
            ) {
                return;
            }
            if (state.focussedLayer.type === type && matchLayerIdentifier(state.focussedLayer, layer)) {
                return true;
            }
            return false;
        },
        [layer, type, topLevelLayoutUid, topLevelFootprintUid],
    );
};

const useFocusedNodeByMaterial = (
    layer: string | undefined,
    material: PcbBoardLayerMaterials,
    topLevelLayoutUid?: string,
    topLevelFootprintUid?: string,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            if (state.focussedLayer === undefined) return;

            const activeRootLayoutUid = state.activeLayoutUid;

            if (activeRootLayoutUid !== topLevelLayoutUid && activeRootLayoutUid !== topLevelFootprintUid) {
                return;
            }

            if (
                material === "Copper" &&
                isCopperLayer(state.focussedLayer) &&
                matchLayerIdentifier(state.focussedLayer, layer)
            ) {
                return true;
            }

            if (state.focussedLayer.material === material && layer === state.focussedLayer.orientation) {
                return true;
            }
            return false;
        },
        [layer, material, topLevelLayoutUid, topLevelFootprintUid],
    );
};

// TODO: this is too slow
const useLayerUidByMaterial = (
    layer: LayerOrientation | string | undefined,
    material: PcbBoardLayerMaterials,
    topLevelLayoutUid?: string,
    topLevelFootprintUid?: string,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            let currentLayers;
            if (topLevelLayoutUid) {
                currentLayers = state.layerViewConfigArray[topLevelLayoutUid] || [];
            } else if (topLevelFootprintUid) {
                currentLayers = state.layerViewConfigArray[topLevelFootprintUid] || [];
            }
            // TODO: put memoization in here

            if (currentLayers) {
                let matchingLayer;
                if (isCopperMaterial(material)) {
                    matchingLayer = currentLayers.filter(
                        (currentLayer) => isCopperLayer(currentLayer) && getLayerIdentifier(currentLayer) === layer,
                    );
                } else {
                    matchingLayer = currentLayers.filter(
                        (currentLayer) =>
                            currentLayer.material === material && getLayerIdentifier(currentLayer) === layer,
                    );
                }

                return matchingLayer[0]?.uid;
            }
        },
        [layer, material, topLevelLayoutUid, topLevelFootprintUid],
    );
};

// TODO: this is too slow
const useLayerUidByType = (
    layer: LayerOrientation | string | undefined,
    type: PcbBoardLayerType,
    topLevelLayoutUid?: string,
    topLevelFootprintUid?: string,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            let currentLayers;
            if (topLevelLayoutUid) {
                currentLayers = state.layerViewConfigArray[topLevelLayoutUid] || [];
            } else if (topLevelFootprintUid) {
                currentLayers = state.layerViewConfigArray[topLevelFootprintUid] || [];
            }

            if (currentLayers) {
                const matchingLayer = currentLayers.filter(
                    (currentLayer) => currentLayer.type === type && getLayerIdentifier(currentLayer) === layer,
                );

                return matchingLayer[0]?.uid;
            }
        },
        [layer, type, topLevelLayoutUid, topLevelFootprintUid],
    );
};

const getNodeVisibilityByMaterial = (
    state: IPcbLayerViewStore,
    layer: LayerOrientation | string | undefined,
    material: PcbBoardLayerMaterials,
    rootFootprintOrLayoutUid?: string,
    checkCopperFill = false,
) => {
    if (!state.layerViewConfigArray) {
        return true;
    }

    const activeRootLayoutUid = state.activeLayoutUid;
    if (!activeRootLayoutUid) {
        return true;
    }

    if (!state.layerViewConfigArray[activeRootLayoutUid]) {
        return true;
    }

    if (activeRootLayoutUid !== rootFootprintOrLayoutUid) {
        return true;
    }

    // TODO: put memoization in here
    let layerStates = state.layerViewConfigArray[activeRootLayoutUid]!.filter((layerState) =>
        matchLayerIdentifier(layerState, layer),
    );
    if (material === "Copper") {
        layerStates = layerStates.filter((layerState) => isCopperLayer(layerState));
    } else {
        layerStates = layerStates.filter((layerState) => layerState.material === material);
    }
    return (
        layerStates.length > 0 &&
        layerStates.every((layerState) => !layerState.hidden && (!checkCopperFill || layerState.copperFilled))
    );
};

// TODO: this is too slow
const useNodeVisibilityByMaterial = (
    layer: LayerOrientation | string | undefined,
    material: PcbBoardLayerMaterials,
    rootFootprintOrLayoutUid?: string,
    checkCopperFill = false,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            return getNodeVisibilityByMaterial(state, layer, material, rootFootprintOrLayoutUid, checkCopperFill);
        },
        [rootFootprintOrLayoutUid, material, layer, checkCopperFill],
    );
};

const useNodeVisibilityByMaterialForLayers = (
    layers: IPcbBoardLayerBase[],
    material: PcbBoardLayerMaterials,
    rootFootprintOrLayoutUid?: string,
    checkCopperFill = false,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            return layers.filter((layer) => {
                return getNodeVisibilityByMaterial(
                    state,
                    layer.name,
                    material,
                    rootFootprintOrLayoutUid,
                    checkCopperFill,
                );
            });
        },
        [rootFootprintOrLayoutUid, material, layers, checkCopperFill],
    );
};

// TODO: this is too slow
const useNodeVisibilityByType = (
    layer: LayerOrientation | string | undefined,
    type: PcbBoardLayerType,
    topLevelLayoutUid?: string,
    topLevelFootprintUid?: string,
) => {
    return useCallback(
        (state: IPcbLayerViewStore) => {
            if (!state.layerViewConfigArray) {
                return true;
            }

            const activeRootLayoutUid = state.activeLayoutUid;
            if (!activeRootLayoutUid) {
                return true;
            }

            if (!state.layerViewConfigArray[activeRootLayoutUid]) {
                return true;
            }

            if (
                activeRootLayoutUid !== topLevelLayoutUid &&
                activeRootLayoutUid !== topLevelFootprintUid &&
                type !== "Metadata"
            ) {
                return true;
            }
            // TODO: put memoization in here

            const matchedLayers = (state.layerViewConfigArray[activeRootLayoutUid] ?? []).filter(
                (layerState) =>
                    matchLayerIdentifier(layerState, layer) && layerState.type === type && !layerState.hidden,
            );
            return matchedLayers.length > 0;
        },
        [layer, type, topLevelLayoutUid, topLevelFootprintUid],
    );
};

// NOTE: needs to be used with shallow comparison for proper memoization
const useAirwireMaterialProperties = () => {
    return useCallback((state: IPcbLayerViewStore) => {
        let focussed = undefined;
        const type = "Metadata";
        const layer = "meta";
        if (state.focussedLayer === undefined) focussed = undefined;
        else if (
            state.focussedLayer.type === type &&
            (state.focussedLayer.orientation === layer || state.focussedLayer.uid === layer)
        ) {
            focussed = true;
        } else {
            focussed = false;
        }
        const ratsNestVisible = state.airwireVisible;
        let metaLayerVisible;
        const activeLayoutUid = state.activeLayoutUid;
        if (!state.layerViewConfigArray || !activeLayoutUid) {
            metaLayerVisible = true;
        } else {
            const matchedLayers = (state.layerViewConfigArray[activeLayoutUid] ?? []).filter(
                (layerState) =>
                    matchLayerIdentifier(layerState, layer) && layerState.type === type && !layerState.hidden,
            );
            metaLayerVisible = matchedLayers.length > 0;
        }
        const visible = !metaLayerVisible || !ratsNestVisible ? false : true;
        const transparent = focussed === undefined ? false : true;
        const opacity = focussed === undefined || focussed === true ? 1 : pcbEditorBehaviors.unfocussedLayerOpacity;
        return {visible: visible, transparent: transparent, opacity: opacity};
    }, []);
};

const useHoverBorder = (nodeUid: string, isSelected: boolean) => {
    return useCallback(
        (state: IPcbUiSelectStore) => {
            return state.highlightedNodeUids.includes(nodeUid) && !isSelected;
        },
        [nodeUid, isSelected],
    );
};

const useIsDefaultInteractionState = () => {
    return (state: PcbEditorUiState) => state.interactionState.state === "DEFAULT";
};

const useIsPanningOrRouting = () => {
    return (state: PcbEditorUiState) =>
        state.interactionState.state === "PANNING" ||
        state.interactionState.state === "ROUTING" ||
        state.interactionState.state === "PANNING_PAUSEDROUTING";
};

const useIsPanning = () => {
    return (state: PcbEditorUiState) =>
        state.interactionState.state === "PANNING" ||
        state.interactionState.state === "PANNING_PAUSEDROUTING" ||
        state.interactionState.state === "PANNING_PAUSEDPOLYGON_DRAW";
};

const useIsPolygonDrawing = () => {
    return (state: PcbEditorUiState) =>
        state.interactionState.state === "POLYGON_DRAW" ||
        state.interactionState.state === "PANNING_PAUSEDPOLYGON_DRAW" ||
        state.polygonDrawState;
};

// TODO: add my selector and selector test here

const pcbEditorUISelectors = {
    useHoverBorder,
    useNetRoutingState,
    useFocusedNodeByType,
    useFocusedNodeByMaterial,
    useLayerUidByMaterial,
    useLayerUidByType,
    useNodeVisibilityByType,
    useNodeVisibilityByMaterial,
    useNodeVisibilityByMaterialForLayers,
    useAirwireMaterialProperties,
    useIsDefaultInteractionState,
    useIsPanningOrRouting,
    useIsPanning,
    useIsPolygonDrawing,
};

export default pcbEditorUISelectors;
