import {
    EnterpriseFullyDecoratedOrganizationMember,
    FullyDecoratedOrganizationMember,
    isEnterpriseFullyDecoratedOrganizationMember,
    isNonEnterpriseFullyDecoratedOrganizationMember,
    NonEnterpriseFullyDecoratedOrganizationMember,
    StripeClientMode,
    SubscriptionStatus,
} from "@buildwithflux/models";
import {
    AnyEntitlementContext,
    AnyPlanConfig,
    defaultGetEntitlementSettings,
    EnterpriseEntitlementContext,
    EntitlementKey,
    EntitlementResult,
    GetEntitlementSettings,
    OrganizationEntitlementContext,
    UserEntitlementContext,
} from "@buildwithflux/plans";
import {Logger, silentLogger} from "@buildwithflux/shared";

import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUser, useCurrentUserUid} from "../../auth/state/currentUser";
import {useCurrentPageOrganizationContextMembership} from "../../auth/state/organizationContext";
import {useUserPrivateMetadata} from "../../auth/state/user";

/**
 * Produces an entitlement context dependent on the current page context
 *
 * i.e. if the project being edited is owned by an organization, the entitlement context will be for the current user
 * *and* that organization
 */
function useEntitlementContext(): AnyEntitlementContext {
    return useSpecificEntitlementContext(useCurrentPageOrganizationContextMembership());
}

/**
 * Produces an entitlement context for the current user alone, or for a specific organization/enterprise membership
 */
export function useSpecificEntitlementContext(
    forOrganizationMembership: FullyDecoratedOrganizationMember | undefined,
): AnyEntitlementContext {
    const currentUserContext = useEntitlementContextForCurrentUser();

    if (forOrganizationMembership) {
        return getEntitlementContextForMembership(currentUserContext, forOrganizationMembership);
    }

    return currentUserContext;
}

/**
 * Produces an entitlement context for the current user alone, with respect to none of their organizations or
 * enterprises
 */
function useEntitlementContextForCurrentUser(): UserEntitlementContext {
    const currentUserUid = useCurrentUserUid() ?? "anonymous";
    const currentUser = useCurrentUser() ?? {uid: currentUserUid};
    const currentUserPrivateMetadata = useUserPrivateMetadata() ?? {uid: currentUserUid};

    return {
        type: "user",
        currentUser,
        currentUserPrivateMetadata,
        stripeClientMode: StripeClientMode.enum.live,
    };
}

/**
 * Produces an entitlement for the current user with respect to one of their organization or enterprise memberships
 */
function getEntitlementContextForMembership(
    currentUserContext: UserEntitlementContext,
    membership: FullyDecoratedOrganizationMember,
): OrganizationEntitlementContext | EnterpriseEntitlementContext {
    if (isEnterpriseFullyDecoratedOrganizationMember(membership)) {
        return getEntitlementContextForEnterpriseMembership(currentUserContext, membership);
    } else if (isNonEnterpriseFullyDecoratedOrganizationMember(membership)) {
        return getEntitlementContextForNonEnterpriseOrganizationMembership(currentUserContext, membership);
    }

    throw new Error("Unknown membership type: expected organization or enterprise membership");
}

/**
 * Produces an entitlement context for the specified organization or enterprise membership
 */
function getEntitlementContextForNonEnterpriseOrganizationMembership(
    currentUserContext: UserEntitlementContext,
    membership: NonEnterpriseFullyDecoratedOrganizationMember,
): OrganizationEntitlementContext {
    return {
        ...currentUserContext,
        type: "organization",
        membership,
    };
}

/**
 * Produces an entitlement context for the specified organization or enterprise membership
 */
function getEntitlementContextForEnterpriseMembership(
    currentUserContext: UserEntitlementContext,
    membership: EnterpriseFullyDecoratedOrganizationMember,
): EnterpriseEntitlementContext {
    return {
        ...currentUserContext,
        type: "enterprise",
        membership,
    };
}

/**
 * The main entrypoint hook for checking an entitlement on an account
 *
 * This method will automatically use the relevant plan evaluation context. For example, if the user is viewing a
 * project owned by an organization, the plan context will be with respect to that organization.
 *
 * We expect that resolving entitlements is fast enough that we don't need to memoize the result, until we know
 * better.
 */
export function useEntitlement<K extends EntitlementKey>(
    entitlementKey: K,
    settings: GetEntitlementSettings = defaultGetEntitlementSettings,
): EntitlementResult<K> {
    const context = useEntitlementContext();
    return useEntitlementWithContext(entitlementKey, context, settings);
}

/**
 * A secondary entrypoint hook for checking an entitlement on a specific account by passing in the relevant context
 *
 * This is useful in cases where we know the automatically selected entitlement context is incorrect, such as when
 * opening the plans and payments dialog for the current user, but on a page where an organization might be active
 */
export function useEntitlementWithContext<K extends EntitlementKey>(
    entitlementKey: K,
    context: AnyEntitlementContext,
    settings: GetEntitlementSettings = defaultGetEntitlementSettings,
): EntitlementResult<K> {
    const {planEntitlement} = useFluxServices();
    return planEntitlement.getEntitlements(context, settings)[entitlementKey];
}

/**
 * Reports whether the given account has payment issues with their subscription
 */
export function useAccountHasPaymentIssue(
    forOrganizationMembershipOrCurrentUser: FullyDecoratedOrganizationMember | "currentUser",
    logger: Logger = silentLogger,
): boolean {
    const context = useSpecificEntitlementContext(
        forOrganizationMembershipOrCurrentUser === "currentUser" ? undefined : forOrganizationMembershipOrCurrentUser,
    );
    const planCategory = useEntitlementWithContext("category", context, {includeInactivePlans: true});

    /*
     * This undefined condition indicates the desired membership has not loaded or is not available - we can't check for
     *  a payment issue, so we fall back to saying there isn't one.
     *
     * This also makes this hook more convenient to use (because hooks can't be called conditionally)
     */
    if (planCategory === undefined || context === undefined) {
        return false;
    }

    const contributingPlans = planCategory.contributingPlans;

    if (contributingPlans.length > 1) {
        logger.warn("Multiple plans contributing to the category entitlement - unexpected configuration");
        // We'll use the last of them
    }

    const contributingPlan: AnyPlanConfig | undefined = contributingPlans.pop();

    if (!contributingPlan) {
        logger.warn("No plans contributing to the category entitlement - unexpected configuration");

        // Nothing much we can do here
        return false;
    }

    const planStatus = contributingPlan.status(context);

    return (
        planStatus === SubscriptionStatus.enum.past_due ||
        planStatus === SubscriptionStatus.enum.unpaid ||
        // NOTE: do not consider "incomplete" an issue because it is a temporary state
        planStatus === SubscriptionStatus.enum.incomplete_expired
    );
}
