import grey from "@material-ui/core/colors/grey";
import Grow from "@material-ui/core/Grow";
import {ThemeOptions} from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";

import R from "./Namespace";

// This is needed to add custom keys to the theme for typescript.
// See https://material-ui.com/customization/palette/#adding-new-colors
declare module "@material-ui/core/styles/createPalette" {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface TypeBackground {
        canvas?: string;
        snackbar?: string;
    }
}

const isWebKit154 =
    typeof navigator !== "undefined" &&
    /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
    /(os |version\/)15(.|_)[4-9]/i.test(navigator.userAgent);

const tempTrans = {
    TransitionComponent: isWebKit154 ? Zoom : Grow,
};

export const paperBackgroundColor = "#2E2E2E";

// NOTE: Light mode has never been tested. It is here for future compatibility.
export function getThemeConfig(prefersDarkMode: boolean): ThemeOptions {
    if (prefersDarkMode) {
        return {
            props: {
                // fix safari flicker issue as per https://github.com/mui/material-ui/pull/32304
                MuiPopover: tempTrans,
                MuiTooltip: {
                    ...tempTrans,
                    enterDelay: 1000,
                    enterNextDelay: 100,
                },
            },
            // see https://material-ui.com/customization/palette/
            palette: {
                type: "dark",
                // see https://www.figma.com/file/ddlwY6dkQdcNhVTEc7R7GV/%E2%9D%87%EF%B8%8F-Flux-Design-System?node-id=0%3A7243
                text: {
                    disabled: "rgba(255, 255, 255, 0.38)",
                    hint: "rgba(255, 255, 255, 0.38)",
                    primary: "#FFFFFF",
                    secondary: "#A3A3A3",
                },

                /*
                 * Looking for 'background', 'lightbg' or 'border' colors from Figma? Use the following:
                 *
                 * - The background color is the main color set to 8% (0.08) opacity.
                 * - The border color is the main color set to 50% (0.5) opacity.
                 * - The lightbg color is the main color darkened by 90%
                 *
                 * ```ts
                 * import {fade, darken} from "@material-ui/core/styles";
                 *
                 * const useStyles = makeStyles((theme) => ({
                 *   primaryBorderWithBackground: {
                 *     backgroundColor: fade(theme.palette.primary.main, 0.08),
                 *     borderColor: fade(theme.palette.primary.main, 0.5),
                 *   }
                 *   warningAlert: {
                 *     backgroundColor: darken(theme.palette.warning.main, 0.9),
                 *   }
                 * })
                 * ```
                 *
                 * Here, `fade(color, amount)` means to set the absolute opacity of the color to the amount (it could have
                 * been called setOpacity(color, alpha))
                 */

                primary: {
                    contrastText: "#000000",
                    dark: "#00C990",
                    light: "#9DFEDA",
                    main: "#1DFDC0",
                },

                secondary: {
                    contrastText: "#000000",
                    dark: "#0083CB",
                    light: "#69E4FF",
                    main: "#00B2FF",
                },

                success: {
                    contrastText: "#FFFFFF",
                    dark: "#3B873E",
                    light: "#7BC67E",
                    main: "#4CAF50",
                },

                info: {
                    contrastText: "#FFFFFF",
                    dark: "#0B79D0",
                    light: "#64B6F7",
                    main: "#2196F3",
                },

                warning: {
                    contrastText: "rgba(0, 0, 0, 0.87)",
                    dark: "#C77700",
                    light: "#FFB547",
                    main: "#FF9800",
                },

                error: {
                    contrastText: "#FFFFFF",
                    dark: "#E31B0C",
                    light: "#F88078",
                    main: "#F44336",
                },

                background: {
                    paper: paperBackgroundColor,
                    canvas: "#100F0F",
                    snackbar: grey["800"],
                    // QUESTION: "default" is previous to design system update... do we still need it?
                    // index.html also uses a hard-coded value for background-color. Keep these in sync.
                    // This is not the same as the canvas background color; for that, see colors.ts:colors.canvas.background
                    default: "#212121",
                },
                divider: "#191B1B",
            },
            typography: {
                fontFamily: [R.fonts.primary, "sans-serif"].join(","),
            },
            overrides: {
                MuiMenu: {
                    paper: {
                        backgroundColor: grey["800"],
                    },
                    list: {
                        "& .MuiDivider-root": {
                            margin: "4px 0",
                        },
                        "& .MuiListItemSecondaryAction-root": {
                            marginLeft: "18px",
                        },
                        "& .MuiMenuItem-root": {
                            paddingRight: "64px",
                        },
                        "& .MuiListSubheader-root.dense": {
                            // MUI4 has no dense size prop for ListSubheader
                            padding: "7px 16px",
                            lineHeight: "initial",
                        },
                    },
                },
                MuiListItemIcon: {
                    root: {minWidth: "29px"},
                },
                MuiDialog: {
                    root: {
                        "& .MuiDialogContent-dividers": {
                            padding: "0",
                        },
                    },
                },
                // @ts-ignore
                MuiAutocomplete: {
                    paper: {
                        backgroundColor: grey["800"],
                    },
                    listbox: {
                        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
                            backgroundColor: grey["800"],
                        },

                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            backgroundColor: grey["600"],
                            border: "3px solid " + grey["800"],
                        },
                    },
                },
                MuiCssBaseline: {
                    // TODO: upgrade with v5 of Material-UI that will have this build in. Details: https://next.material-ui.com/components/css-baseline/#scrollbars
                    "@global": {
                        body: {
                            scrollbarColor: grey["600"] + " transparent" /* For Firefox */,
                            scrollbarWidth: "none" /* For Firefox */,
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                                backgroundColor: paperBackgroundColor,
                                width: "8px",
                                height: "8px",
                            },
                            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                                borderRadius: 8,
                                backgroundColor: grey["600"],
                                minHeight: 24,
                                border: "3px solid transparent",
                            },
                            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                                backgroundColor: grey["500"],
                            },
                            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                                backgroundColor: grey["500"],
                            },
                            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: grey["600"] + " transparent",
                            },
                            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                                backgroundColor: "transparent",
                            },
                            // See https://stackoverflow.com/a/64267916/200312
                            '& input[type="search"]::-webkit-search-cancel-button': {
                                "-webkit-appearance": "none",
                            },
                        },
                    },
                },
            },
            zIndex: {
                modal: 10000,
            },
        };
    } else {
        return {
            palette: {
                type: "light",
            },
            typography: {
                fontFamily: [R.fonts.primary, "sans-serif"].join(","),
            },
        };
    }
}
