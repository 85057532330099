import {EditorModes, PcbLayoutRuleCompiler} from "@buildwithflux/core";
import {IProblem} from "@buildwithflux/models";

import type {IDrcInputs, IDrcValidator} from "../types";

export class DeprecatedRulesValidator implements IDrcValidator {
    problemTypeKey = "deprecated_rules";
    problemLabel = "Deprecated Rules";
    problemDescription = "Reports nodes that are using rules that are not supported anymore.";
    defaultHidden = true;

    checkForProblems({pcbLayoutRuleSets, pcbLayoutNodes}: IDrcInputs) {
        const deprecatedRulesUsages = new Map<string, IProblem["affectedItems"]>();

        Object.values(pcbLayoutRuleSets).forEach((ruleSet) =>
            Object.values(ruleSet.rules).forEach((rule) => {
                if (PcbLayoutRuleCompiler.isRuleDeprecated(rule)) {
                    const record = deprecatedRulesUsages.get(rule.key) ?? [];
                    record.push({type: "ruleSet" as const, uid: ruleSet.uid});
                    deprecatedRulesUsages.set(rule.key, record);
                }
            }),
        );

        Object.values(pcbLayoutNodes).forEach((node) =>
            Object.values(node.pcbNodeRuleSet).forEach((rule) => {
                if (PcbLayoutRuleCompiler.isRuleDeprecated(rule)) {
                    const record = deprecatedRulesUsages.get(rule.key) ?? [];
                    record.push({type: "pcbLayoutNode" as const, uid: node.uid});
                    deprecatedRulesUsages.set(rule.key, record);
                }
            }),
        );

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: Array.from(deprecatedRulesUsages.entries()).map(([ruleKey, affectedItems]) => ({
                problemTypeKey: this.problemTypeKey,
                key: `${this.problemTypeKey}_${ruleKey}`,
                label: `${ruleKey} is deprecated`,
                affectedItems,
                affectedViews: [EditorModes.pcb],
            })),
        };
    }
}
