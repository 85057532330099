import {
    ChatMessageContext,
    CopilotModel,
    IChatMessageHumanMetaData,
    IChatMessageThread,
    MessageReaction,
} from "@buildwithflux/models";
import {ChatRepository} from "@buildwithflux/repositories";
import {Logger, silentLogger, Unsubscriber} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {values} from "lodash";
import {create, StoreApi, UseBoundStore} from "zustand";
import {immer} from "zustand/middleware/immer";

import {CurrentUserService} from "../../../../../../../../../modules/auth";

import {branchMessage, createMessage, deleteMessage, setActionExecuted} from "./actions/messages";
import {removeMessageReaction, setMessageReaction} from "./actions/reactions";
import {setCurrentDocumentUid} from "./actions/setCurrentDocumentUid";
import {
    createThread,
    deleteThread,
    setCurrentThread,
    setCurrentThreadToLatest,
    updateThreadName,
} from "./actions/threads";

type CopilotChatStoreApi = {
    setCurrentDocumentUid: (documentUid: string) => void;

    setCurrentThread: (threadUid: string) => void;
    setCurrentThreadToLatest: () => void;

    createThread: () => Promise<void>;
    updateThreadName: (threadUid: string, name: string) => Promise<void>;
    deleteThread: (threadUid: string) => Promise<void>;

    createMessage: (threadUid: string, content: string, metaData: IChatMessageHumanMetaData) => Promise<void>;
    branchMessage: (
        threadUid: string,
        messageUid: string,
        content?: string,
        metaData?: Partial<IChatMessageHumanMetaData>,
    ) => Promise<void>;
    deleteMessage: (threadUid: string, messageUid: string) => Promise<void>;
    setActionExecuted: (threadUid: string, messageUid: string) => Promise<void>;

    // Drafting a message
    setDraftMessage: (threadUid: string, text: string) => void;
    setDraftMessageModel: (threadUid: string, model: CopilotModel) => void;
    setDraftMessageContexts: (threadUid: string, contexts: ChatMessageContext[] | undefined) => void;
    setDraftMessageFocused: (threadUid: string, focused: boolean) => void;

    stopMessageStreaming: (threadUid: string) => Promise<void>;

    makeCurrentMessageBranch: (threadUid: string, messageUid: string) => void;

    setMessageReaction: (threadUid: string, messageUid: string, reaction: MessageReaction) => Promise<void>;
    removeMessageReaction: (threadUid: string, messageUid: string) => Promise<void>;
};

/**
 * Represents the message in the user text input at the bottom of a thread, before the user has sent it
 */
type DraftMessage = {text: string; model?: CopilotModel; selectedContexts?: ChatMessageContext[]; focused?: boolean};
type DraftMessages = {[threadUid: string]: DraftMessage};

export interface ICopilotChatStore {
    currentDocumentUid?: string;
    currentThreadUid?: string;

    threads: {[threadUid: string]: IChatMessageThread};
    draftMessages: DraftMessages; // messages the user is still typing
    threadsLoaded: boolean;
    threadLoaded: boolean;

    threadsSubscription?: Unsubscriber | undefined;
    threadMessagesSubscription?: Unsubscriber | undefined;
    threadMessagesReactionsSubscription: {[messageUid: string]: Unsubscriber | undefined};
}

export type CopilotChatStoreState = CopilotChatStoreApi & ICopilotChatStore;
export type UseCopilotChatStore = UseBoundStore<StoreApi<CopilotChatStoreState>>;

export const createCopilotChatStoreHook = (
    currentUserService: CurrentUserService,
    _documentService: DocumentService,
    chatRepository: ChatRepository,
    logger: Logger = silentLogger,
): UseCopilotChatStore => {
    return create<CopilotChatStoreState>()(
        immer((set, get) => ({
            threads: {},
            draftMessages: {},
            threadsLoaded: false,
            threadLoaded: false,
            threadMessagesReactionsSubscription: {},

            // API
            setCurrentDocumentUid: setCurrentDocumentUid(set, get, chatRepository, logger),

            // Threads
            setCurrentThreadToLatest: setCurrentThreadToLatest(set, get),
            setCurrentThread: setCurrentThread(set, get, chatRepository, logger),
            createThread: createThread(set, get, currentUserService, chatRepository, logger),
            updateThreadName: updateThreadName(set, get, chatRepository, logger),
            deleteThread: deleteThread(set, get, chatRepository, logger),

            // Messages
            createMessage: createMessage(set, get, currentUserService, chatRepository, logger),
            setDraftMessage: (threadUid, text) => {
                set((state) => {
                    const prev = state.draftMessages[threadUid];
                    state.draftMessages[threadUid] = {
                        ...prev,
                        text,
                    };
                });
            },
            setDraftMessageModel: (threadUid, model) => {
                set((state) => {
                    const prev = state.draftMessages[threadUid] ?? {text: ""};
                    state.draftMessages[threadUid] = {
                        ...prev,
                        model,
                    };
                });
            },
            setDraftMessageContexts: (threadUid, contexts) => {
                set((state) => {
                    const prev = state.draftMessages[threadUid] ?? {text: ""};
                    state.draftMessages[threadUid] = {
                        ...prev,
                        selectedContexts: contexts,
                    };
                });
            },
            setDraftMessageFocused: (threadUid, focused) => {
                set((state) => {
                    const prev = state.draftMessages[threadUid] ?? {text: ""};
                    state.draftMessages[threadUid] = {
                        ...prev,
                        focused,
                    };
                });
            },
            branchMessage: branchMessage(set, get, currentUserService, chatRepository, logger),
            deleteMessage: deleteMessage(set, get, chatRepository, logger),
            setActionExecuted: setActionExecuted(set, get, chatRepository, logger),
            stopMessageStreaming: async (threadUid: string) => {
                const {currentDocumentUid, threads} = get();

                const originalThread = threads[threadUid]; // Original thread for rollback

                // Early exit if prerequisites are not met
                if (!currentDocumentUid || !originalThread) return;

                let updatedThread;

                set((draft) => {
                    const thread = draft.threads[threadUid];
                    if (!thread) return;

                    const streamingCopilotMessage = values(thread.messages).find(
                        (msg) => msg.meta_data.type === "copilot" && msg.meta_data.message_status === "streaming",
                    );

                    if (!streamingCopilotMessage) return;
                    if (streamingCopilotMessage.meta_data.type !== "copilot") return;

                    streamingCopilotMessage.meta_data.cancel_streaming_at_position =
                        streamingCopilotMessage.content.length;
                    streamingCopilotMessage.updated_at = new Date().getTime();
                    thread.updated_at = new Date().getTime();

                    updatedThread = thread;
                });

                if (!updatedThread) {
                    throw new Error("Thread not found");
                }

                try {
                    // Persist the updated thread to the backend
                    await chatRepository.setThread(currentDocumentUid, updatedThread);
                } catch (error) {
                    logger.error("Failed to update thread:", error);

                    // Rollback the optimistic update on failure
                    set((draft) => {
                        draft.threads[threadUid] = originalThread;
                    });
                }
            },
            makeCurrentMessageBranch: (threadUid: string, messageUid: string) =>
                set((draft) => {
                    const thread = draft.threads[threadUid];
                    if (!thread) return;

                    const message = thread.messages[messageUid];
                    if (!message) return;

                    message.updated_at = new Date().getTime();
                    thread.updated_at = new Date().getTime();
                }),

            // Reactions
            setMessageReaction: setMessageReaction(set, get, currentUserService, chatRepository, logger),
            removeMessageReaction: removeMessageReaction(set, get, currentUserService, chatRepository, logger),
        })),
    );
};
