import {
    AnyPcbNode,
    getAncestorUidByType,
    PcbBakedNode,
    PcbNode,
    PcbNodeHelper,
    PcbNodeTypes,
    PcbRule,
    vec2,
    getStartEndPosition,
} from "@buildwithflux/core";
import {createRouteSegmentNode, IDocumentData, IVector2, LayoutRules} from "@buildwithflux/models";
import {guid, humanizeLengthValue, mmToMeters} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {pick} from "lodash";
import {Vector2} from "three";
import {StoreApi, UseBoundStore} from "zustand";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";
import {FluxServices} from "../../../../../injection";
import {
    PcbEditorUiStore,
    selectProjectedTraceWidth,
    usePcbEditorUiStore,
} from "../../../../../modules/stores/pcb/PcbEditorUiStore";
import {SnapToSegment} from "../../../../../modules/stores/pcb/PcbEditorUiStore.types";
import type {AppThunkAction, IApplicationStore} from "../../../../../store";

// QUESTION: move to constants package?
export const pseudoStartTouchpointId = "pseudoStart";
export const pseudoEndTouchpointId = "pseudoEnd";
interface IParams {
    /**
     * Id of the start vertex
     * @deprecated
     */
    startId: string;
    /**
     * Id of the end vertex
     * @deprecated
     */
    endId: string;
    /**
     * Segments we are adding
     */
    // TODO: Interface for segment??
    segments: Array<{start: IVector2; end: IVector2}>;
    /**
     * uid of the parent node holding these segments
     */
    parentNetId: string;
    /**
     * Used to calculate relative positions
     */
    parentNetBakedNode: PcbBakedNode<PcbNodeTypes.net>;
    /**
     * Layer of new segments
     */
    layer: string;
    /**
     * Custom trace width of new segments
     */
    humanizedTraceWidth?: string;
    /**
     * Via node that we might be placing during routing
     */
    via?: PcbNode<PcbNodeTypes.smartVia | PcbNodeTypes.via>;
    snapToSegment?: SnapToSegment | null;
    routeFromSegment?: SnapToSegment | null;
    /**
     * The zustand store storing `projectedHumanizedTraceWidth` which will be used to determine trace
     * width for the new trace.
     *
     * We can't directly import this because that will end up with circular import, and plus that's not
     * a good pattern
     *
     * TODO: Move this to service container so it is injected in runtime and we dont need to pass it in
     */
    pcbEditorUiStore: UseBoundStore<StoreApi<PcbEditorUiStore>>;
    /**
     * Once the route segments are instanciated (before adding them), the function sets this value.
     * This pattern is necessary to avoid waiting for the function to return (async).
     */
    addedUidsRef?: {current: string[]};
}

// this deals with the special case of routing to / from an existing segment
function splitExistingSegment(
    segmentToSplit: SnapToSegment,
    variant: "start" | "end",
    segments: Array<{start: IVector2; end: IVector2}>,
    document: IDocumentData,
    startId: string,
    endId: string,
    layer: string,
    parentNetId: string,
    parentNetBakedNode: PcbBakedNode<PcbNodeTypes.net>,
) {
    const segment = segments.at(variant === "start" ? 0 : -1);
    if (!segment) {
        throw new Error(`Expected segment at '${variant}' to be defined`);
    }

    const segmentSplitTarget = document.pcbLayoutNodes[segmentToSplit.id];
    if (!segmentSplitTarget) {
        throw new Error(`Expected split target segment to be defined`);
    }

    const existingStart = PcbNodeHelper.scenePositionToNodeSpace(segmentToSplit.start, parentNetBakedNode);
    const existingEnd = PcbNodeHelper.scenePositionToNodeSpace(segmentToSplit.end, parentNetBakedNode);

    const vertex = segment[variant];
    const intersectionPoint = PcbNodeHelper.scenePositionToNodeSpace(vertex, parentNetBakedNode);

    const firstSegmentToAdd = createRouteSegmentNode(
        segmentToSplit.startId,
        variant === "start" ? startId : endId,
        {x: existingStart.x, y: existingStart.y},
        intersectionPoint,
        parentNetId,
        layer,
        (segmentSplitTarget.pcbNodeRuleSet.traceWidth?.value as string) ||
            (segmentSplitTarget.pcbNodeRuleSet.sizeX?.value as string),
        undefined,
        parentNetBakedNode.bakedRules.layer,
    );
    const secondSegmentToAdd = createRouteSegmentNode(
        segmentToSplit.endId,
        endId,
        {x: existingEnd.x, y: existingEnd.y},
        intersectionPoint,
        parentNetId,
        layer,
        (segmentSplitTarget.pcbNodeRuleSet.traceWidth?.value as string) ||
            (segmentSplitTarget.pcbNodeRuleSet.sizeX?.value as string),
        undefined,
        parentNetBakedNode.bakedRules.layer,
    );

    const nodesToAdd: AnyPcbNode[] = [];
    if (firstSegmentToAdd) {
        nodesToAdd.push(firstSegmentToAdd);
    }
    if (secondSegmentToAdd) {
        nodesToAdd.push(secondSegmentToAdd);
    }

    return {
        nodesToAdd,
        nodesToDelete: [segmentSplitTarget],
    };
}

function getNodesToAddFromSegments(
    segments: Array<{start: IVector2; end: IVector2}>,
    startId: string,
    endId: string,
    layer: string,
    parentNetId: string,
    parentNetBakedNode: PcbBakedNode<PcbNodeTypes.net>,
    projectedTraceWidth?: number,
    projectedHumanizedTraceWidth?: string,
) {
    const nodesToAdd: AnyPcbNode[] = [];
    let vertexId = startId;
    function getSegmentLength(segment: {start: IVector2; end: IVector2}) {
        return new Vector2(segment.start.x, segment.start.y).distanceTo(new Vector2(segment.end.x, segment.end.y));
    }
    function addBaseSegment(segment: {start: IVector2; end: IVector2}, idx: number) {
        const isEnd = idx === segments.length - 1;
        const currentVertexId = guid();
        const absStart = PcbNodeHelper.scenePositionToNodeSpace(segment.start, parentNetBakedNode);
        const absEnd = PcbNodeHelper.scenePositionToNodeSpace(segment.end, parentNetBakedNode);
        let segmentEndVertex: IVector2 = absEnd;
        const segmentLength = getSegmentLength(segment);

        // If `segmentLength` is less than its own trace width, make the length
        // at least its own trace width (fall back to minTraceWidth). Hence the
        // shortest segment is a dot
        const minTraceWidthNum =
            projectedTraceWidth ??
            // NOTE: This assumes LayoutRules.minTraceWidth.default is in mm
            parseFloat(LayoutRules.minTraceWidth.default as string);
        if (segmentLength < mmToMeters(minTraceWidthNum)) {
            segmentEndVertex = {
                x: absStart.x + Math.sqrt(Math.pow(mmToMeters(minTraceWidthNum), 2) / 2),
                y: absStart.y + Math.sqrt(Math.pow(mmToMeters(minTraceWidthNum), 2) / 2),
            };
        }

        const normalized = normalizeCenter(absStart, segmentEndVertex);
        const humanizedCenter = [normalized.center.x, normalized.center.y]
            .map((v) => humanizeLengthValue(v, parentNetBakedNode.bakedRules.unit))
            .join(" ");

        const newSegment = createRouteSegmentNode(
            vertexId,
            isEnd ? endId : currentVertexId,
            normalized.start,
            normalized.end,
            parentNetId,
            layer,
            projectedHumanizedTraceWidth,
            humanizedCenter,
            parentNetBakedNode.bakedRules.layer,
        );

        if (newSegment) {
            nodesToAdd.push(newSegment);
        }
        vertexId = currentVertexId;
    }
    segments.forEach(addBaseSegment);

    return nodesToAdd;
}

function normalizeCenter(start: IVector2, end: IVector2) {
    const centerX = (start.x + end.x) / 2;
    const centerY = (start.y + end.y) / 2;
    return {
        center: {x: centerX, y: centerY},
        start: {x: start.x - centerX, y: start.y - centerY},
        end: {x: end.x - centerX, y: end.y - centerY},
    };
}

export function addRouteSegmentThunk(params: IParams): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        dispatch(addRouteSegment(params, services, services.reduxStoreService.getStore()));
    };
}

/**
 * NOTE: see also commitMultiRoutingUpdate
 */
export const addRouteSegment = createActionRecordAction(
    "addRouteSegment",
    (params: IParams, fluxServices: FluxServices, store: IApplicationStore) => {
        const rootState = store.getState();
        const document = rootState.document;
        if (!document) {
            throw new Error(`Expected document to be defined`);
        }

        const {
            startId,
            endId,
            segments,
            parentNetId,
            layer,
            via,
            snapToSegment,
            routeFromSegment,
            addedUidsRef,
            parentNetBakedNode,
            humanizedTraceWidth,
        } = params;

        const uiState = usePcbEditorUiStore.getState();
        const nodesToAdd: AnyPcbNode[] = [];
        const nodesToDelete: AnyPcbNode[] = [];

        const finalStartId = startId === pseudoStartTouchpointId ? guid() : startId;
        const finalEndId = endId === pseudoEndTouchpointId ? guid() : endId;

        // We don't want to set a trace width rule if it's not necessary to change the final result
        const projectedTraceWidth = selectProjectedTraceWidth(uiState, rootState.document, true);
        const selectedTraceWidth = selectProjectedTraceWidth(uiState, rootState.document, false, humanizedTraceWidth);
        const shouldSetTraceWidth = Boolean(projectedTraceWidth !== selectedTraceWidth);

        nodesToAdd.push(
            ...getNodesToAddFromSegments(
                segments,
                finalStartId,
                finalEndId,
                layer,
                parentNetId,
                parentNetBakedNode,
                selectedTraceWidth,
                shouldSetTraceWidth
                    ? humanizedTraceWidth ?? uiState.selectedHumanizedTraceWidth ?? uiState.projectedHumanizedTraceWidth
                    : undefined,
            ),
        );

        if (snapToSegment) {
            // i.e. when we have ended routing by clicking on an existing trace
            const splitExistingSegmentGoods = splitExistingSegment(
                snapToSegment,
                "end",
                segments,
                document,
                finalStartId,
                finalEndId,
                layer,
                parentNetId,
                parentNetBakedNode,
            );
            nodesToAdd.push(...splitExistingSegmentGoods.nodesToAdd);
            nodesToDelete.push(...splitExistingSegmentGoods.nodesToDelete);
        }

        if (routeFromSegment) {
            // i.e. when we have started routing by double-clicking on an existing trace
            const splitExistingSegmentGoods = splitExistingSegment(
                routeFromSegment,
                "start",
                segments,
                document,
                finalStartId,
                finalEndId,
                layer,
                parentNetId,
                parentNetBakedNode,
            );
            nodesToAdd.push(...splitExistingSegmentGoods.nodesToAdd);
            nodesToDelete.push(...splitExistingSegmentGoods.nodesToDelete);
        }

        // When we're not routing from, or two, a midpoint generated along an existing route segment (eg, to/from a touchpoint
        // that's inserted after double click on an existing route segment), when we'll check to see if routing is going to or
        // from a route touchpoint that's repositioned at the transition between (a possible) curve and straight-line portion of
        // a trace
        if (!snapToSegment && !routeFromSegment) {
            const pcbNodes = fluxServices.documentService.snapshot().pcbLayoutNodes;

            const toSegmentId = endId.replace(/_end|_start$/, "");
            const toSegment = pcbNodes[toSegmentId];

            const fromSegmentId = startId.replace(/_end|_start$/, "");
            const fromSegment = pcbNodes[fromSegmentId];

            if (toSegment?.type === PcbNodeTypes.routeSegment) {
                const [startPosition, endPosition] = getStartEndPosition(toSegment);

                const jointPosition = segments.at(-1)?.end;
                if (!jointPosition) {
                    throw new Error("Curve trace joint position not defined");
                }

                const start = PcbNodeHelper.scenePositionToNodeSpace(startPosition, parentNetBakedNode);
                const end = PcbNodeHelper.scenePositionToNodeSpace(endPosition, parentNetBakedNode);
                const joint = PcbNodeHelper.scenePositionToNodeSpace(jointPosition, parentNetBakedNode);
                const jointId = guid();

                // Only insert non-zero length segments to ensure curvature is present on inserted/adjoined segments
                // If a zero-length segment is present it prevents curvature between itself and adjoined traces being possible
                if (vec2.distance(start, joint) > 0) {
                    nodesToAdd.push(
                        createRouteSegmentNode(
                            startId,
                            jointId,
                            {x: start.x, y: start.y},
                            joint,
                            parentNetId,
                            layer,
                            (toSegment.pcbNodeRuleSet.traceWidth?.value as string) ||
                                (toSegment.pcbNodeRuleSet.sizeX?.value as string),
                            undefined,
                            parentNetBakedNode.bakedRules.layer,
                        ),
                    );
                }

                // Only insert non-zero length segments to ensure curvature is present on inserted/adjoined segments
                // If a zero-length segment is present it prevents curvature between itself and adjoined traces being possible
                if (vec2.distance(joint, end) > 0) {
                    nodesToAdd.push(
                        createRouteSegmentNode(
                            jointId,
                            endId,
                            joint,
                            {x: end.x, y: end.y},
                            parentNetId,
                            layer,
                            (toSegment.pcbNodeRuleSet.traceWidth?.value as string) ||
                                (toSegment.pcbNodeRuleSet.sizeX?.value as string),
                            undefined,
                            parentNetBakedNode.bakedRules.layer,
                        ),
                    );
                }

                nodesToDelete.push(toSegment);
            }

            if (fromSegment?.type === PcbNodeTypes.routeSegment) {
                const [startPosition, endPosition] = getStartEndPosition(fromSegment);

                const jointPosition = segments.at(0)?.start;
                if (!jointPosition) {
                    throw new Error("Curve trace joint position not defined");
                }

                const start = PcbNodeHelper.scenePositionToNodeSpace(startPosition, parentNetBakedNode);
                const end = PcbNodeHelper.scenePositionToNodeSpace(endPosition, parentNetBakedNode);
                const joint = PcbNodeHelper.scenePositionToNodeSpace(jointPosition, parentNetBakedNode);
                const jointId = guid();

                // Only insert non-zero length segments to ensure curvature is present on inserted/adjoined segments
                // If a zero-length segment is present it prevents curvature between itself and adjoined traces being possible
                if (vec2.distance(joint, start) > 0) {
                    nodesToAdd.push(
                        createRouteSegmentNode(
                            startId,
                            jointId,
                            {x: start.x, y: start.y},
                            joint,
                            parentNetId,
                            layer,
                            (fromSegment.pcbNodeRuleSet.traceWidth?.value as string) ||
                                (fromSegment.pcbNodeRuleSet.sizeX?.value as string),
                            undefined,
                            parentNetBakedNode.bakedRules.layer,
                        ),
                    );
                }

                // Only insert non-zero length segments to ensure curvature is present on inserted/adjoined segments
                // If a zero-length segment is present it prevents curvature between itself and adjoined traces being possible
                if (vec2.distance(joint, end) > 0) {
                    nodesToAdd.push(
                        createRouteSegmentNode(
                            jointId,
                            endId,
                            joint,
                            {x: end.x, y: end.y},
                            parentNetId,
                            layer,
                            (fromSegment.pcbNodeRuleSet.traceWidth?.value as string) ||
                                (fromSegment.pcbNodeRuleSet.sizeX?.value as string),
                            undefined,
                            parentNetBakedNode.bakedRules.layer,
                        ),
                    );
                }

                nodesToDelete.push(fromSegment);
            }
        }

        // Place a via if in the middle of routing
        if (via) {
            nodesToAdd.push(via);
        }

        if (addedUidsRef) {
            addedUidsRef.current = nodesToAdd.map((n) => n.uid);
        }

        const updatedNodePositions = getUpdatedElementNodePositions(
            document,
            startId,
            endId,
            fluxServices.documentService,
        );

        return {
            payload: {
                addedNodes: nodesToAdd,
                deletedNodes: nodesToDelete,
                updatedNodePositions,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated PCB net nodes",
);

/**
 * Gets the current positions of elements to fix in place against
 * autopositioning when a trace is connected to them.
 *
 * See evaluateVector3 in PcbLayoutRuleParser.
 */
function getUpdatedElementNodePositions(
    document: IDocumentData,
    startId: string,
    endId: string,
    documentService: DocumentService,
): Record<string, PcbRule> {
    const pcbLayoutNodes = document?.pcbLayoutNodes ?? {};
    const padNodes = Object.values(pick(pcbLayoutNodes, [startId, endId]));
    const elementNodeUids = padNodes
        .map((padNode) => getAncestorUidByType(pcbLayoutNodes, padNode.uid, PcbNodeTypes.element))
        .filter(Boolean);
    return Object.fromEntries(
        elementNodeUids
            .map((nodeUid) => {
                const bakedElementNode = PcbNodeHelper.getNodeOfType(
                    documentService.snapshot().pcbLayoutNodes,
                    nodeUid,
                    PcbNodeTypes.element,
                );
                const bakedRules = bakedElementNode?.bakedRules;
                if (bakedRules && (bakedRules.position.autoX || bakedRules.position.autoY)) {
                    const xPositionValue = humanizeLengthValue(bakedRules.position.x, bakedRules.unit);
                    const yPositionValue = humanizeLengthValue(bakedRules.position.y, bakedRules.unit);
                    const position: PcbRule = {
                        uid: "position",
                        key: "position",
                        value: `${xPositionValue} ${yPositionValue}`,
                        disabled: false,
                    };

                    return [nodeUid, position];
                }
                return [nodeUid, undefined];
            })
            .filter(([uid, pos]) => !!uid && !!pos) as [string, PcbRule][],
    );
}
