import {IUserData, IUserPrivateMetadata} from "@buildwithflux/models";

export type SurveyData = {
    fullName: string;
    role: string | undefined;
    company: string | undefined;

    /**
     * As persisted in Firestore, this is a comma-separated list of strings
     */
    tools: string | undefined;

    hearAboutFlux: string | undefined;

    /**
     * @deprecated We stopped asking this in Dec 2024.
     */
    primaryUse?: string;
};

export function getCurrentSurveyData({
    user,
    privateMetadata,
}: {
    user: IUserData | undefined;
    privateMetadata: IUserPrivateMetadata | undefined;
}): SurveyData {
    return {
        fullName: user?.full_name ?? "",
        role: privateMetadata?.personal?.role ?? undefined,
        company: privateMetadata?.personal?.company ?? undefined,
        tools: privateMetadata?.personal?.tools ?? undefined,
        hearAboutFlux: privateMetadata?.personal?.hearAboutFlux ?? undefined,
    };
}

export function surveyDataIsComplete(surveyData: Partial<SurveyData>): boolean {
    return (
        (surveyData.fullName?.length ?? 0) > 0 &&
        surveyData.role !== undefined &&
        surveyData.company !== undefined &&
        surveyData.tools !== undefined &&
        surveyData.hearAboutFlux !== undefined
    );
}
