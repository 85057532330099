import {
    PrimitiveRole,
    AnyPcbBakedNode,
    EditorModes,
    PcbNodeTypes,
    filterTruthy,
    isOverlappingApprox,
} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

// IMPORTANT: This algorithm works in 2D only and it only finds the collisions on the XY plane projection

function getNetUidForNode(node: AnyPcbBakedNode) {
    const nodeHasNet =
        node.type === PcbNodeTypes.pad || node.type === PcbNodeTypes.via || node.type === PcbNodeTypes.routeSegment;

    if (!nodeHasNet) {
        return null;
    }

    return node.bakedRules.hostNetId ?? null;
}

// TODO: put this in some common configuration
const polygonApproxSegments = 100;

export class OverlappingCopperValidator implements IDrcValidator {
    problemTypeKey = "overlapping_copper";
    problemLabel = "Overlapping Copper";
    problemDescription = "Reports copper elements that are shorting.";

    checkForProblems(inputs: IDrcInputs) {
        const pcbQuadTreeNodes = Array.from(inputs.nodeShapesMap.values());

        const overlapCache: {[key: string]: boolean} = {};

        try {
            const collisions = filterTruthy(
                pcbQuadTreeNodes.map((rsA) => {
                    const nodeA = inputs.pcbLayoutNodes[rsA.nodeUid];
                    if (!nodeA) {
                        return null;
                    }

                    const layoutId = nodeA.bakedRules.topLevelLayoutUid;
                    if (!layoutId) {
                        return null;
                    }

                    // This "container" has a node quadTree per layout
                    const container = inputs.containerMap.get(layoutId);
                    if (!container) {
                        return null;
                    }
                    const tree = container.quadTree;

                    return {
                        node: nodeA,
                        collidesWith: tree.search(rsA).filter((rsB) => {
                            const nodeB = inputs.pcbLayoutNodes[rsB.nodeUid];
                            if (!nodeB) {
                                return false;
                            }

                            // A collision with itself is not a collision
                            const isSameNode = nodeA.uid === nodeB.uid;
                            if (isSameNode) {
                                return false;
                            }

                            // Compose a node pair key to cache read/write in this iteration.
                            // Ordering the ids means that the cache storing pair overlaps
                            // can be accessed bi-directionally
                            const keyOverlapPair =
                                rsB.nodeUid < rsA.nodeUid
                                    ? `${rsB.nodeUid}_${rsA.nodeUid}`
                                    : `${rsA.nodeUid}_${rsB.nodeUid}`;

                            // If the overlap cache contains a defined value for the pair,
                            // then that's our opportunity to early exit and avoid redundant
                            // computation
                            const overlap = overlapCache[keyOverlapPair];
                            if (overlap !== undefined) {
                                return overlap;
                            }

                            const netA = getNetUidForNode(nodeA);
                            const netB = getNetUidForNode(nodeB);
                            const isSameNet = netA === netB;
                            if (isSameNet) {
                                return false;
                            }

                            // TODO: wonder if primitives should be organized by role in an object
                            const copperPrimitivesA = rsA.primitives.filter(
                                (primitive) => primitive.role === PrimitiveRole.copper,
                            );
                            const copperPrimitivesB = rsB.primitives.filter(
                                (primitive) => primitive.role === PrimitiveRole.copper,
                            );

                            for (const copperPrimitiveA of copperPrimitivesA) {
                                for (const copperPrimitiveB of copperPrimitivesB) {
                                    if (copperPrimitiveA.layerId === copperPrimitiveB.layerId) {
                                        if (
                                            isOverlappingApprox(
                                                copperPrimitiveA,
                                                copperPrimitiveB,
                                                inputs.clipperShapeFactory,
                                                polygonApproxSegments,
                                            )
                                        ) {
                                            // Overlap detected between shapes of either node.
                                            // Before early exit, mark the pair as overlapping
                                            // in the overlap cache to allow early exit in
                                            // subsequent iteration
                                            overlapCache[keyOverlapPair] = true;

                                            return true;
                                        }
                                    }
                                }
                            }

                            // No overlap detected between shapes of either node. To avoid
                            // unnecessary re-checking, mark the pair as non-overlapping
                            overlapCache[keyOverlapPair] = false;

                            return false;
                        }),
                    };
                }),
            ).filter((coll) => coll.collidesWith.length !== 0);

            // We eliminate the duplicates this way, since every collision between A and B will produce a pair of AB/BA
            const collisionKeys = Array.from(
                new Set(
                    collisions.flatMap((col) =>
                        col.collidesWith.map((colWith) => [col.node.uid, colWith.nodeUid].sort().join("+")),
                    ),
                ),
            );
            return {
                error: false as const,
                problemTypeKey: this.problemTypeKey,
                foundProblems: collisionKeys.map((collisionKey) => ({
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${collisionKey}`,
                    affectedItems: collisionKey.split("+").map((uid) => ({type: "pcbLayoutNode" as const, uid})),
                    affectedViews: [EditorModes.pcb],
                })),
            };
        } catch (e) {
            return {
                error: "cannot compute overlaps for this PCB",
                problemTypeKey: this.problemTypeKey,
                foundProblems: [],
            };
        }
    }
}
