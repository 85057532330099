import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {SnackbarKey, SnackbarOrigin, SnackbarProvider} from "notistack";
import React, {CSSProperties, useCallback} from "react";
import {batchActions} from "redux-batched-actions";

import {LeftRight} from "../../../components/common/components/Stack";
import {useFluxSnackbar} from "../../../components/common/hooks/useFluxSnackbar";
import {useFluxServices} from "../../../injection/hooks";
import {removeNotification, toggleEditorBackDrop} from "../../../redux/reducers/app/actions";
import R from "../../../resources/Namespace";

export function customSnackbarStyle(theme: Theme): CSSProperties | Record<string, CSSProperties> {
    return {
        color: theme.palette.text.primary,
        padding: "2px 12px",
        fontSize: theme.typography.body2.fontSize,
        backgroundColor: theme.palette.background.snackbar,
        maxWidth: "475px",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginRight: "8px",
        },
        "& button .MuiSvgIcon-root": {
            marginRight: 0,
        },
        "& #notistack-snackbar": {
            maxWidth: "350px",
        },
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        containerRoot: {
            marginTop: "55px",
            zIndex: 99999,
            "& .MuiCollapse-container > .MuiCollapse-wrapper": {
                padding: "4px 0",
            },
        },
        contentRoot: customSnackbarStyle(theme),
        variantSuccess: {
            backgroundColor: theme.palette.success.main,
        },
        variantError: {
            backgroundColor: theme.palette.error.main,
        },
        variantWarning: {
            backgroundColor: theme.palette.warning.main,
        },
        variantInfo: {backgroundColor: theme.palette.info.main},
    }),
);

const defaultAnchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: "center",
};

// Override the default "x" icon used by notistack, which is confusing because it looks like a "close" icon.
const defaultIconVariants = {
    error: <ErrorOutlineIcon />,
};

export function SnackbarCloseActionHelper(key: SnackbarKey) {
    return <SnackbarCloseAction snackbarKey={key} />;
}

function SnackbarCloseAction(props: {snackbarKey: SnackbarKey}) {
    const {closeSnackbar} = useFluxSnackbar();

    const close = useCallback(() => {
        closeSnackbar(props.snackbarKey);
    }, [closeSnackbar, props.snackbarKey]);

    return (
        <>
            <Button size="small" aria-label="close" color="inherit" onClick={close}>
                OK
            </Button>
        </>
    );
}

/**
 * For creating a snackbar message that includes a link.
 */
export function snackbarMessageHelper(message: string, url: string, urlText: string, postMessage?: string) {
    return <SnackbarMessage message={message} url={url} urlText={urlText} postMessage={postMessage} />;
}

/**
 * For creating a snackbar message that includes a link.
 */
function SnackbarMessage(props: {message: string; url: string; urlText: string; postMessage?: string}) {
    return (
        <Typography variant={"body2"}>
            {props.message + " "}
            <Link underline="always" color="textPrimary" href={props.url} target={"_blank"}>
                {props.urlText}
            </Link>
            {props.postMessage ? " " + props.postMessage : ""}
        </Typography>
    );
}

/**
 * For creating the "error generating PCB" snackbar message that has inline
 * links and a dismiss button.
 */
export function snackbarErrorGeneratingHelper(message: string) {
    return <SnackbarErrorGeneratingMessage message={message} />;
}

/**
 * For creating the "error generating PCB" snackbar message that has inline
 * links and a dismiss button.
 */
function SnackbarErrorGeneratingMessage(props: {message: string}) {
    const {useDocumentUiStore, reduxStoreService} = useFluxServices();
    const dispatch = reduxStoreService.getStore().dispatch;

    const closeSnackbar = useCallback(
        () => dispatch(batchActions([toggleEditorBackDrop(false), removeNotification("syncError2")])),
        [dispatch],
    );
    const reloadPage = useCallback(() => {
        closeSnackbar();
        window.location.reload();
    }, [closeSnackbar]);
    const openChangeHistory = useCallback(() => {
        closeSnackbar();
        useDocumentUiStore.getState().showChangeHistoryPanel();
    }, [closeSnackbar, useDocumentUiStore]);
    return (
        <LeftRight>
            <Typography variant={"body2"}>
                {props.message + " "}
                <Link color="textPrimary" onClick={reloadPage}>
                    Reload the page
                </Link>
                ,{" "}
                <Link color="textPrimary" onClick={openChangeHistory}>
                    restore a version
                </Link>
                , or{" "}
                <Link underline="always" color="textPrimary" href={R.externalUrls.marketing.feedback} target={"_blank"}>
                    report a bug
                </Link>
                .
            </Typography>
            <Button size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
                OK
            </Button>
        </LeftRight>
    );
}

function SnackbarCustomProvider(props: {children?: React.ReactNode}) {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={3}
            classes={classes}
            dense
            anchorOrigin={defaultAnchorOrigin}
            iconVariant={defaultIconVariants}
        >
            {props.children}
        </SnackbarProvider>
    );
}

export default React.memo(SnackbarCustomProvider);
