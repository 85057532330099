import {
    AccountUid,
    BillingInterval,
    isOrganizationUid,
    isPaidPaymentPlanCategory,
    Organization,
    PaymentPlanCategory,
    PaymentPlanUids,
    UserStateType,
} from "@buildwithflux/models";

import {useCurrentUserAuthenticationState} from "../../auth/state/currentUser";
import {useCurrentOrganizationMembershipForOrganization} from "../../auth/state/organization";

import {useEntitlementWithContext, useSpecificEntitlementContext} from "./plan";

export type UrlType = "user" | "organization";

export type GetPortalUrlOptions<OwnerType extends UrlType> = OwnerType extends "organization"
    ? {
          active?: boolean;
          returnUrl: string;
          ownerType: "organization";
          organization: Organization | undefined; // Only optional so we can skip if no organization given (react hook ergonomics)
      }
    : {active?: boolean; returnUrl: string; ownerType: "user"};

export type GetCheckoutUrlOptions<OwnerType extends UrlType> = OwnerType extends "organization"
    ? {
          active?: boolean;
          ownerType: "organization";
          organization: Organization | undefined; // Only optional so we can skip if no organization given (react hook ergonomics)
          interval: BillingInterval;
          cancelUrl: string;
      }
    : {
          active?: boolean;
          ownerType: "user";
          successUrl: string;
          interval: BillingInterval;
          plan:
              | typeof PaymentPlanCategory.enum.userLegacyPro
              | typeof PaymentPlanCategory.enum.userLegacyUltra
              | typeof PaymentPlanCategory.enum.userPro
              | typeof PaymentPlanCategory.enum.userStarter;
          /** @deprecated - will no longer need to be sent after 2024-10 */
          priceUid: string;
          cancelUrl: string;
      };

export function useExistingPaymentInformation(options: {
    accountUid: AccountUid | undefined;
    hasPaidActiveSubscription?: boolean;
    hasActiveOrInactiveSubscription?: boolean;
}) {
    const {accountUid} = options;
    const organizationUid = isOrganizationUid(accountUid) ? accountUid : undefined;

    const currentUserAuthenticationState = useCurrentUserAuthenticationState();

    const context = useSpecificEntitlementContext(undefined);
    const planCategory = useEntitlementWithContext("category", context)?.value;
    const planCategoryIncludingInactivePlans = useEntitlementWithContext("category", context, {
        includeInactivePlans: true,
    })?.value;

    const userHasPaidActiveSubscription = isPaidPaymentPlanCategory(planCategory);
    const userHasActiveOrInactiveSubscription = isPaidPaymentPlanCategory(planCategoryIncludingInactivePlans);
    const organizationMembership = useCurrentOrganizationMembershipForOrganization(organizationUid);

    // Because only org-owners (and users wrt themselves) have permission to manage payments
    const hasPermissionToManagePayments =
        currentUserAuthenticationState === UserStateType.enum.authenticated &&
        (!organizationUid || (!!organizationMembership && organizationMembership.role === "owner"));

    // Because the billing portal can't be used until subscribed
    // We believe the argument more than our own hooks, because the caller knows best, and may be running a snapshot listener
    //  or trusting e.g. a URL parameter on a successful checkout
    const hasPaidActiveSubscription: boolean =
        options.hasPaidActiveSubscription !== undefined
            ? options.hasPaidActiveSubscription
            : (!organizationUid && userHasPaidActiveSubscription) ||
              (!!organizationUid &&
                  organizationMembership?.organization?.activeProductUid === PaymentPlanUids.organizationPaid);

    return {
        hasPermissionToManagePayments,
        hasPaidActiveSubscription,
        hasActiveOrInactiveSubscription: options.hasActiveOrInactiveSubscription
            ? options.hasActiveOrInactiveSubscription
            : userHasActiveOrInactiveSubscription,
    };
}
