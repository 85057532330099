import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {
    DocumentReducerError,
    ElementHelper,
    getProperty,
    PcbTreeManager,
    removeNodesAndDescendants,
    setElementsAndGenerateNodes,
} from "@buildwithflux/core";
import {IDocumentData} from "@buildwithflux/models";
import {guid} from "@buildwithflux/shared";
import {Dictionary, omit} from "lodash";

import {createActionRecordAction} from "../../../../helpers/actionCreator";
import {FluxLogger} from "../../../../modules/storage_engine/connectors/LogConnector";
import {MixedOrSingleProperty} from "../../../selectors/document/common/selectors";

type SubjectType = "elements" | "routes" | "nets";
export type PropertyUpdateMap = {
    [subjectUid: string]: {subjectType: SubjectType; propertyUpdates: Dictionary<MixedOrSingleProperty>};
};

export const setSubjectProperties = createActionRecordAction(
    "setSubjectProperties",
    (propertyUpdateMap: PropertyUpdateMap) => {
        return {
            payload: {
                propertyUpdateMap,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added or updated properties for subjects",
);

export function handleSetSubjectProperties(draftState: IDocumentData, action: ReturnType<typeof setSubjectProperties>) {
    const {propertyUpdateMap} = action.payload;

    for (const [subjectUid, {subjectType, propertyUpdates}] of Object.entries(propertyUpdateMap)) {
        const subject =
            subjectType === "elements"
                ? draftState.elements?.[subjectUid]
                : subjectType === "routes"
                ? draftState.routes?.[subjectUid]
                : subjectType === "nets"
                ? draftState.nets?.[subjectUid]
                : undefined;

        if (!subject) {
            throw new DocumentReducerError(
                action.type,
                `Can't set subject properties since the subject does not exist in ${subjectType} collection`,
                action,
            );
        }

        const isBatchEdit = Object.values(propertyUpdateMap).length > 1;

        for (const setProperty of Object.values(propertyUpdates)) {
            if (isBatchEdit) {
                // If updating properties of multiple objects (eg for batch property
                // edit), we need to update the existing property, not add a new
                // property -- the provided property is likely a different subject's
                // property, and adding it to this subject will cause it to have a
                // duplicate entry for that property. For some default properties
                // like part_type, this matters less, because the key "part_type" is
                // used as the uid, and so only one entry can exist. But many
                // properties (like "section") have unique uids, and end up with
                // multiple entries during batch edit.
                const updateExisting = getProperty(setProperty.name, subject.properties);

                // Ensure property.state doesn't get saved to the document.
                const updatedProperty = {
                    ...omit(setProperty, "state"),
                    // If property doesn't exist, add it (eg for when the user adds a
                    // property via the property dialog)
                    uid: updateExisting?.uid ?? guid(),
                };
                subject.properties[updatedProperty.uid] = updatedProperty;
            } else {
                if (!("uid" in setProperty)) {
                    throw new Error("Single-subject properties must have a uid");
                }
                subject.properties[setProperty.uid] = setProperty;
            }
        }

        const propertiesRequiringNodeRegenerate = [
            defaultPropertyDefinitions.exclude_from_pcb!.label,
            defaultPropertyDefinitions.pin_number!.label,
        ];
        const nodeRegenerateRequired = Object.values(propertyUpdates).some((p) =>
            propertiesRequiringNodeRegenerate.includes(p.name),
        );

        // setElementsAndGenerateNodes() etc is expensive, so don't execute it for most property
        // changes -- only ones that affect the nodes, like pin_number and exclude_from_pcb.
        if (nodeRegenerateRequired) {
            const excludeFromPcb = ElementHelper.isExcludedFromPcb(subject.properties); // Important that subject.properties has been set above so we're using the new value if changed.
            const excludeFromPcbWasChanged = Object.values(propertyUpdates).some(
                (p) => p.name === defaultPropertyDefinitions.exclude_from_pcb!.label,
            );

            if (excludeFromPcbWasChanged && excludeFromPcb) {
                // If user has changed it so the node is excluded from PCB, remove it from PCB object tree.
                removeNodesAndDescendants(draftState.pcbLayoutNodes, [subjectUid]);
            }

            if (!excludeFromPcb) {
                if (subjectType === "elements") {
                    setElementsAndGenerateNodes(draftState, [draftState.elements[subjectUid]!]);
                }
                // QUESTION: how to add only the nodes from the
                // subjectUid? or do we need this in case the element is
                // a layout or footprint?
                const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                pcbDomTreeManager.updateNetNodes(draftState);
            }
        }
    }
}
