import {AsyncClipperShapeFactory, PcbPrimitiveStore, PcbTracingGraph} from "@buildwithflux/core";
import type {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    AutoLayoutApi,
    type AutoLayoutApiVersion,
    type AutoLayoutIterationClientData,
    type AutoLayoutJob,
    AutoLayoutJobMetadata,
    type AutoLayoutJobUid,
    AutoLayoutStatus,
    IUserData,
    type OrganizationUid,
    StorageEvent,
    UserJobOfType,
} from "@buildwithflux/models";
import type {AutoLayoutJobMetadataRepository, AutoLayoutJobRepository} from "@buildwithflux/repositories";
import {Logger, Unsubscriber} from "@buildwithflux/shared";
import type {DocumentService} from "@buildwithflux/solder-core";
import axios from "axios";

import {getTimeElapsedInSeconds} from "../../../helpers/dateAndTime";
import {applyAutoLayoutIterationThunk} from "../../../redux/reducers/document/pcbLayoutNodes/actions";
import type {ReduxStoreService} from "../../../redux/util/service";
import type {CurrentUserService} from "../../auth";
import {AliffExporter} from "../../data_portability/exporters/AliffExporter/AliffExporter";
import type {AnalyticsStorage} from "../../storage_engine/AnalyticsStorage";
import {SurfaceBasedTrackingEvents} from "../../storage_engine/common/SurfaceBasedTrackingEvents";
import {PcbBakedGoodsManager} from "../../stores/pcb/PcbBakedGoodsManager";
import {usePcbEditorUiStore} from "../../stores/pcb/PcbEditorUiStore";
import {UseAutoLayoutStore} from "../state";
import {LocalAutoLayoutState} from "../types";

import {AutoLayoutIterationProcessor} from "./AutoLayoutIterationProcessor";
import {CloseSnackbarFn, type AutoLayoutService, type ShowSnackbarFn} from "./AutoLayoutService";

export class DeepPcbAutoLayoutService implements AutoLayoutService {
    // TODO: This private field introduces a dependency to the UI component, it's ok right now because
    // we initialize them in run-time, and so no circular dependency, but this is bad in general and we should remove this arrow
    public showSnackbar: ShowSnackbarFn | null = null;

    private jobUnsub: Unsubscriber | undefined;
    private jobMeatadataUnsub: Unsubscriber | undefined;

    constructor(
        private readonly reduxStoreService: ReduxStoreService,
        private readonly functionsAdapter: ClientFunctionsAdapter,
        private readonly currentUserService: CurrentUserService,
        private readonly pcbPrimitiveStore: PcbPrimitiveStore,
        private readonly autoLayoutJobRepository: AutoLayoutJobRepository,
        private readonly autoLayoutJobMetadataRepository: AutoLayoutJobMetadataRepository,
        private readonly documentService: DocumentService,
        private readonly pcbBakedGoodsManager: PcbBakedGoodsManager,
        private readonly useAutoLayoutStore: UseAutoLayoutStore,
        private readonly iterationProcessor: AutoLayoutIterationProcessor,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly pcbTracingGraph: PcbTracingGraph,
        private readonly logger: Logger,
    ) {}

    /* @inheritDoc */
    public init(showSnackbar: ShowSnackbarFn, closeSnackbar: CloseSnackbarFn) {
        this.showSnackbar = showSnackbar;

        this.iterationProcessor.init(showSnackbar, closeSnackbar);
    }

    /* @inheritDoc */
    public async startJob(
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<AutoLayoutJobUid | undefined> {
        const {
            reduxStoreService,
            functionsAdapter,
            currentUserService,
            pcbPrimitiveStore,
            logger,
            analyticsStorage,
            documentService,
            autoLayoutJobRepository,
            autoLayoutJobMetadataRepository,
            useAutoLayoutStore,
            pcbTracingGraph,
            showSnackbar,
        } = this;

        // Optimistic update local store
        useAutoLayoutStore.getState().queue();

        const projectUid = reduxStoreService.getStore().getState().document?.uid;
        if (!projectUid) return;

        // Create the job, and get the uploadUrl from backend
        const result = await functionsAdapter.createAutoLayoutJob({
            projectUid,
            organizationUid: organizationUid,
        });

        const {data} = result;
        if (data.type === "success") {
            const {uploadUrl, jobUid} = data;
            const {
                document,
                documentMeta: {documentOwner},
            } = reduxStoreService.getStore().getState();
            const currentUser = currentUserService.getCurrentUser();

            if (!document) {
                showSnackbar?.("genericError");
                logger.error("Document not found in store");
                return;
            }

            if (!documentOwner) {
                showSnackbar?.("genericError");
                logger.error("Document owner not found in store");
                return;
            }

            if (!currentUser) {
                // Should be impossible, but just a null check
                showSnackbar?.("genericError");
                logger.error("Current user not found in store");
                return;
            }

            // Update the jobUid
            useAutoLayoutStore.getState().setJobUid(jobUid);

            const clipperShapeFactory = await new AsyncClipperShapeFactory().load();
            const aliffJsonExporter = new AliffExporter(
                autoLayoutVersion,
                document,
                documentOwner,
                pcbPrimitiveStore,
                reduxStoreService,
                documentService,
                clipperShapeFactory,
                pcbTracingGraph,
            );
            const aliffBoard = aliffJsonExporter.generateBoard();

            try {
                // TODO: Need to check with Nick to see what config we need to do in order for cdn2.flux.ai domain to work
                await axios.put(
                    uploadUrl.replace("cdn2.flux.ai", "storage.googleapis.com"),
                    JSON.stringify(aliffBoard),
                    {
                        headers: {
                            // Need to unset Content-Type to make it work...
                            "Content-Type": "",
                        },
                    },
                );
            } catch (error) {
                showSnackbar?.("genericError");
                logger.error("Failed to upload board to GCS", error);
                return;
            }

            // Subscription to job - monitoring status and iterations
            this.jobUnsub = autoLayoutJobRepository.subscribe(currentUser.handle, jobUid, (event) => {
                this.onJobChange(event, organizationUid, autoLayoutVersion);
            });

            // Subscription to more-frequent metadata responses
            this.jobMeatadataUnsub = autoLayoutJobMetadataRepository.subscribe(currentUser, jobUid, (event) => {
                this.onJobMetadataChange(event);
            });

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {surface: "AutoLayout", action: "start"});

            return jobUid;
        } else {
            // Failure, show reason in snackbar
            showSnackbar?.("genericError");

            logger.error("Failed to create auto layout job", data.reason);

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                surface: "AutoLayout",
                action: "error",
                reason: data.reason,
                timeElapsedInSeconds: getTimeElapsedInSeconds(new Date().getTime()),
            });
        }
    }

    /* @inheritDoc */
    public async findAndLoadActiveJob(
        currentUser: IUserData,
        projectUid: string,
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<void> {
        const {autoLayoutJobRepository, useAutoLayoutStore, autoLayoutJobMetadataRepository} = this;

        return autoLayoutJobRepository
            .getActiveJobForProject(currentUser.handle, currentUser.uid, projectUid)
            .then((activeJob) => {
                // Prevent duplicate subscriptions
                const activeJobUid = activeJob?.jobUid;
                if (activeJobUid === useAutoLayoutStore.getState().jobUid) {
                    return;
                }

                if (activeJobUid) {
                    // TODO: Logic here should be combined with `handleStartAutoLayout`, and maybe in a service
                    useAutoLayoutStore.getState().load("startTime" in activeJob ? activeJob.startTime : undefined);

                    // Update the jobUid
                    useAutoLayoutStore.getState().setJobUid(activeJobUid);

                    // Subscription to iterations
                    this.jobUnsub = autoLayoutJobRepository.subscribe(currentUser.handle, activeJobUid, (event) => {
                        this.onJobChange(event, organizationUid, autoLayoutVersion);
                    });

                    // Subscription to more-frequent metadata responses
                    this.jobMeatadataUnsub = autoLayoutJobMetadataRepository.subscribe(
                        currentUser,
                        activeJobUid,
                        (event) => {
                            this.onJobMetadataChange(event);
                        },
                    );
                }
            });
    }

    /* @inheritDoc */
    public async cancelJob(autoLayoutVersion: AutoLayoutApiVersion): Promise<void> {
        const {useAutoLayoutStore} = this;
        // Optimistic cancel job
        useAutoLayoutStore.getState().cancel();

        await this.stopJob("cancel", autoLayoutVersion);
        usePcbEditorUiStore.getState().clearAutoLayoutData();
        this.forceRebake();
    }

    /* @inheritDoc */
    public pauseJob(organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion): void {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        // Optimistic pause
        useAutoLayoutStore.getState().pause();

        const currentUser = currentUserService.getCurrentUser();

        if (!currentUser) return;
        axios
            .post(AutoLayoutApi.urlFor(AutoLayoutApi.action.pause, autoLayoutVersion), {
                userUid: currentUser.uid,
                jobUid: useAutoLayoutStore.getState().jobUid,
                organizationUid,
            })
            .catch((error) => {
                showSnackbar?.("genericError");
                useAutoLayoutStore.getState().resume();
                logger.error("Failed to pause auto layout job", error);
            });
    }

    /* @inheritDoc */
    public resumeJob(organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion): void {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        // Optimistic resume
        useAutoLayoutStore.getState().resume();

        const currentUser = currentUserService.getCurrentUser();

        if (!currentUser) return;
        axios
            .post(AutoLayoutApi.urlFor(AutoLayoutApi.action.resume, autoLayoutVersion), {
                userUid: currentUser.uid,
                jobUid: useAutoLayoutStore.getState().jobUid,
                organizationUid,
            })
            .catch((error) => {
                showSnackbar?.("genericError");
                useAutoLayoutStore.getState().pause();
                logger.error("Failed to resume auto layout job", error);
            });
    }

    /* @inheritDoc */
    public async applyIteration(
        autoLayoutVersion: AutoLayoutApiVersion,
        iteration: AutoLayoutIterationClientData,
    ): Promise<void> {
        // Optimistic apply
        this.useAutoLayoutStore.getState().apply();

        // Clear the auto router state
        usePcbEditorUiStore.getState().clearAutoLayoutData();

        // Apply the iteration
        // We don't need to call `forceRebake` here, as the redux change thru
        // `applyAutoLayoutIterationThunk` will trigger a re-bake
        const allNodes = this.documentService.snapshot().pcbLayoutNodes;
        this.reduxStoreService.getStore().dispatch(applyAutoLayoutIterationThunk(allNodes, iteration));

        // QUESTION: Should we remove this forceRebake then?
        this.forceRebake();

        // Update the job state to "applied" in firestore
        await this.stopJob("apply", autoLayoutVersion);
    }

    /* @inheritDoc */
    public async saveSelectedIteration(jobUid: AutoLayoutJobUid, index: number): Promise<void> {
        const existingIterationData = usePcbEditorUiStore.getState().autoLayoutIterationData;
        const targetIteration = existingIterationData?.iterations[index];
        const currentUser = this.currentUserService.getCurrentUser();

        if (!targetIteration) {
            this.logger.error(`Trying to persist a non-existing iteration at index: ${index}`);
            return;
        }

        if (!currentUser) {
            this.logger.error(`Trying to persist an iteration for unknown user`);
            return;
        }

        await this.autoLayoutJobRepository.saveSelectedIteration(currentUser.handle, jobUid, targetIteration.hash);
    }

    /* @inheritDoc */
    public shutdown() {
        this.unsubscribeJob();
    }

    public unsubscribeJob() {
        this.jobMeatadataUnsub?.();
        this.jobUnsub?.();
        this.jobMeatadataUnsub = undefined;
        this.jobUnsub = undefined;
    }

    /**
     * Update the job state to "applied"/"cancelled" in firestore thru cloudrun
     */
    private async stopJob(reason: "cancel" | "apply", autoLayoutVersion: AutoLayoutApiVersion): Promise<void> {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        const jobUid = useAutoLayoutStore.getState().jobUid;
        if (!jobUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        try {
            await axios.post(
                AutoLayoutApi.urlFor(
                    reason === "cancel" ? AutoLayoutApi.action.discard : AutoLayoutApi.action.apply,
                    autoLayoutVersion,
                ),
                {
                    userUid: currentUser.uid,
                    jobUid,
                    reason,
                },
            );
        } catch (error) {
            showSnackbar?.("genericError");
            logger.error("Failed to stop auto layout job", error);
        }
    }

    private onJobConverged(job: AutoLayoutJob) {
        if (job.jobStatus !== AutoLayoutStatus.converged) return;

        this.analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
            surface: "AutoLayout",
            action: "converged",
            currentIteration: this.getCurrentIteration()?.iterationIndex,
            timeElapsedInSeconds: getTimeElapsedInSeconds(job.startTime),
        });
    }

    private onJobCompleted(job: AutoLayoutJob) {
        if (job.jobStatus !== AutoLayoutStatus.timeout) return;

        this.analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
            surface: "AutoLayout",
            action: "timeout",
            currentIteration: this.getCurrentIteration()?.iterationIndex,
            timeElapsedInSeconds: getTimeElapsedInSeconds(job.startTime),
        });
    }

    /**
     * TODO: Should bind usePcbEditorUiStore...
     */
    private getCurrentIteration(): AutoLayoutIterationClientData | undefined {
        const pcbEditorUiState = usePcbEditorUiStore.getState();

        if (pcbEditorUiState.autoLayoutIterationData?.currentIterationIndex !== undefined) {
            return pcbEditorUiState.autoLayoutIterationData.iterations[
                pcbEditorUiState.autoLayoutIterationData.currentIterationIndex
            ];
        }
    }

    private async forceRebake() {
        const {reduxStoreService, pcbBakedGoodsManager} = this;
        const document = reduxStoreService.getStore().getState().document;
        if (document) {
            try {
                await pcbBakedGoodsManager.reload(document, document.pcbLayoutNodes, document.pcbLayoutRuleSets);
            } catch (error) {
                this.logger.error("Error in force rebake", error);
            }
        }
    }

    private onJobMetadataChange(event: StorageEvent<AutoLayoutJobMetadata>): void {
        if (event.type === "updated") {
            this.useAutoLayoutStore.getState().setUsedCredits(event.data.creditsUsed);
        }
    }

    private async onJobChange(
        event: StorageEvent<UserJobOfType<"autoLayout">>,
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<void> {
        const {currentUserService, useAutoLayoutStore, showSnackbar, logger} = this;
        const {
            state,
            queue,
            pause,
            paused,
            resume,
            resumed,
            applied,
            canceled,
            reset,
            complete,
            tick,
            noCredits,
            error,
            load,
        } = useAutoLayoutStore.getState();

        /**
         * If local state is idle, we are not running any job and hence
         * should ignore any incoming events
         */
        if (state === LocalAutoLayoutState.idle) return;

        if (event.type === "updated") {
            const currentUser = currentUserService.getCurrentUser();
            const job = event.data;

            // Dont do anything with canceled/canceling jobs, except discarded
            if (
                (state === LocalAutoLayoutState.canceling || state === LocalAutoLayoutState.canceled) &&
                job.jobStatus !== AutoLayoutStatus.discarded
            )
                return;

            if (
                job.jobStatus === AutoLayoutStatus.allocated ||
                job.jobStatus === AutoLayoutStatus.populated ||
                job.jobStatus === AutoLayoutStatus.submitted ||
                job.jobStatus === AutoLayoutStatus.ready ||
                job.jobStatus === AutoLayoutStatus.booting
            ) {
                // Maps to local "waiting" state
                queue();

                // When job is in "ready" state, we send to "/confirm" to transition it to "booting"
                if (job.jobStatus === AutoLayoutStatus.ready && currentUser) {
                    try {
                        await axios.post(AutoLayoutApi.urlFor(AutoLayoutApi.action.confirm, autoLayoutVersion), {
                            userUid: currentUser.uid,
                            jobUid: job.jobUid,
                            organizationUid,
                        });
                    } catch (err) {
                        const errorMessage = "Failed to stop auto layout job";
                        error(errorMessage);
                        logger.error(errorMessage, err);
                    }
                }
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.applied) {
                applied();

                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                showSnackbar?.("applied", {});
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.failed) {
                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                const totalIterationsCount =
                    usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations.length ?? 0;
                if (totalIterationsCount === 0 || totalIterationsCount === 1) {
                    showSnackbar?.("genericError");
                    // When no iterations, we cannot resume the job, and there's no
                    // iterations for users to apply, so we just show error in snackbar
                    // and put back to idle state
                    reset();
                } else {
                    // We don't need to call showSnackbar in this case because we have a setup in an useEffect above
                    // to show snackbar based on local state
                    // TODO: We should prob clean this up a bit... right now we are essentially subscribing to 2 sources of truth:
                    // one is here - data direct from firestore
                    // the other is the local state
                    // Probably we should change here to just update the job in local store, and UI should ALWAYS respond to local store?
                    // Error state
                    error(job.reason);
                }
                return;
            }

            // Map intermediate states for pause/resume
            if (job.jobStatus === AutoLayoutStatus.pausing) {
                pause();
            }
            if (job.jobStatus === AutoLayoutStatus.resuming) {
                resume();
            }

            // Map paused and restarted state
            if (job.jobStatus === AutoLayoutStatus.restarted && state !== LocalAutoLayoutState.running) {
                resumed();
            }

            if (job.jobStatus === AutoLayoutStatus.paused && state !== LocalAutoLayoutState.paused) {
                paused();
            }

            if (job.jobStatus === AutoLayoutStatus.discarded) {
                // Stop the auto layout state
                canceled();

                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                showSnackbar?.("cancel");
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.converged || job.jobStatus === AutoLayoutStatus.timeout) {
                // NOTE: We dont unsubscribe to job/metadata when system-paused
                // At this stage, our backend already sent "stopJob" request to DeepPcb, and should ignore any iterations/steps
                // because the job is no longer marked as "running" on our end.
                // But we still want to receive status changes of the job in our end
                complete();
                tick(job.startTime);

                if (job.jobStatus === AutoLayoutStatus.converged) {
                    this.onJobConverged(job);
                }
                if (job.jobStatus === AutoLayoutStatus.timeout) {
                    this.onJobCompleted(job);
                }
                // We don't want to early return here, since we still want to update the local
                // state with the latest iteration data
            }

            /*
             * TODO: Handle other states:
             *       Paused, Pausing, Resuming, Started, Restarted, etc.
             *       We should probably change this to an compiler-guaranteed
             *       exahaustive switch statement.
             */

            if (job.jobStatus === AutoLayoutStatus.outOfCredit) {
                noCredits();
            }

            // `resuming` is an intermediate state that will transition
            // to `restarted` if successfully resume. Here we just reduce that to "Working" state in frontend
            if (job.jobStatus === AutoLayoutStatus.started || job.jobStatus === AutoLayoutStatus.restarted) {
                // Set local state to start, and use the startTime from
                // backend to init the local startTime
                load(job.startTime);
            }

            // Handle started job, update layout with the received iterations
            try {
                // Using a promise queue to avoid race conditions from receiving multiple events at once,
                // causing the currentIterationIndex to unintentionally "un-stick" from the end
                await this.iterationProcessor.processIterations(job);
            } catch (err) {
                const errorMessage = "Failed to update auto layout data from board";
                error(errorMessage);
                logger.error(errorMessage, err);
            }
        }
    }
}
