import {AliffIterationImporter} from "@buildwithflux/core";
import {
    createAutoLayoutIterationClientData,
    DUMMY_ITERATION,
    DUMMY_ITERATION_HASH,
    StartedAutoLayoutJob,
} from "@buildwithflux/models";
import {Logger, PromiseQueue} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {SnackbarKey} from "notistack";

import {ReduxStoreService} from "../../../export";
import {getTimeElapsedInSeconds} from "../../../helpers/dateAndTime";
import {AnalyticsStorage} from "../../storage_engine/AnalyticsStorage";
import {SurfaceBasedTrackingEvents} from "../../storage_engine/common/SurfaceBasedTrackingEvents";
import {PcbBakedGoodsManager} from "../../stores/pcb/PcbBakedGoodsManager";
import {usePcbEditorUiStore} from "../../stores/pcb/PcbEditorUiStore";
import {AutoLayoutBoardFetcher} from "../board_fetcher";
import {UseAutoLayoutStore} from "../state";

import {AutoLayoutIterationProcessor} from "./AutoLayoutIterationProcessor";
import {CloseSnackbarFn, ShowSnackbarFn} from "./AutoLayoutService";

export class DeepPcbAutoLayoutIterationProcessor implements AutoLayoutIterationProcessor {
    // TODO: These two private fields introduce a dependency to the UI component, it's ok right now because
    // we initialize them in run-time, and so no circular dependency, but this is bad in general and we should remove this arrow
    private showSnackbar: ShowSnackbarFn | null = null;
    private closeSnackbar: ((key?: SnackbarKey) => void) | null = null;

    private updateIterationIndexQueue: PromiseQueue;
    private setAutoLayoutDataQueue: PromiseQueue;

    constructor(
        private readonly reduxStoreService: ReduxStoreService,
        private readonly pcbBakedGoodsManager: PcbBakedGoodsManager,
        private readonly documentService: DocumentService,
        private readonly boardFetcher: AutoLayoutBoardFetcher,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly useAutoLayoutStore: UseAutoLayoutStore,
        private readonly logger: Logger,
    ) {
        this.setAutoLayoutDataQueue = new PromiseQueue();
        this.updateIterationIndexQueue = new PromiseQueue();
    }

    public init(showSnackbar: ShowSnackbarFn, closeSnackbar: CloseSnackbarFn): void {
        this.showSnackbar = showSnackbar;
        this.closeSnackbar = closeSnackbar;
    }

    /**
     * @inheritdoc
     *
     * Renders an iteration we've set to local store by:
     * - If the board data is not loaded yet, fetch it and set in local store
     * - Call "forceRebake" to update data we stored in the partitioned stores and so will trigger a re-render in UI
     *
     * Note this function does check if the requested `targetIndex` is still selected, and if not, we will skip processing that render request
     */
    public async renderIteration(
        targetIndex: number,
        shouldLog = false,
        isCurrentIterationBaked = true,
    ): Promise<void> {
        const {updateIterationIndexQueue, analyticsStorage, useAutoLayoutStore, documentService} = this;
        const task = async () => {
            const iterations = usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations;
            const totalIterations = iterations?.length ?? 0;
            if (targetIndex < 0 || targetIndex >= totalIterations) return;

            // The slider is no longer on this index, so we don't need to update
            if (targetIndex !== useAutoLayoutStore.getState().sliderPosition) return;

            // Whether the target index is already stored in the local state to be consumed when baking
            const isTargetIndexCurrentIterationIndex =
                targetIndex === usePcbEditorUiStore.getState().autoLayoutIterationData?.currentIterationIndex;
            if (isTargetIndexCurrentIterationIndex && isCurrentIterationBaked) return;

            // Finally, in this state, we have the targetIndex matching the slider position,
            // and the target index is either:
            // - already stored in pcbEditorUiStore, but still hasn't been baked
            // - not stored/updated in pcbEditorUiStore

            // Update the current iteration index in the local store
            usePcbEditorUiStore.getState().setAutoLayoutCurrentIterationIndex(targetIndex);

            const targetIteration = iterations?.[targetIndex];

            // If iteration board not loaded yet, fetch it
            if (targetIteration && !targetIteration.loaded) {
                const board = await this.boardFetcher.fetchBoard(targetIteration.boardLocation);

                const allNodes = documentService.snapshot().pcbLayoutNodes;
                const importer = new AliffIterationImporter(allNodes);
                const loadedIterationClientData = importer.generateAutoLayoutIteration(
                    targetIteration,
                    targetIndex + 1,
                    board,
                );
                usePcbEditorUiStore.getState().setAutoLayoutIterationData(loadedIterationClientData);
            }

            const totalIterationsCount = usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations.length ?? 0;

            try {
                await this.forceRebake();

                if (shouldLog) {
                    analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                        surface: "AutoLayout",
                        action: "changeIteration",
                        targetIteration: targetIndex,
                        totalIterations,
                        isLatestIteration: targetIndex === totalIterationsCount,
                        timeElapsedInSeconds: getTimeElapsedInSeconds(useAutoLayoutStore.getState().startTime),
                    });
                }
            } catch (error) {
                // We don't want to show a snackbar here
                // The baking-process can produce errors many times that are not always
                // critical to the user or the auto-layout functionality

                this.logger.error("Error while force rebaking", error);

                if (shouldLog) {
                    analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                        surface: "AutoLayout",
                        action: "changeIterationError",
                        targetIteration: targetIndex,
                        totalIterations,
                        isLatestIteration: targetIndex === totalIterationsCount,
                        reason: error?.toString(),
                        timeElapsedInSeconds: getTimeElapsedInSeconds(useAutoLayoutStore.getState().startTime),
                    });
                }
            }
        };

        // Show snackbar regularly if the rendering takes time
        // TODO: Think about how to de-couple the show/close snackbar operations from this module
        let isResolvedOrRejected = false;
        const renderIterationPromise = updateIterationIndexQueue.add(task);
        // Show a loading snackbar if the iteration change takes more than 500ms
        setTimeout(() => {
            if (shouldLog && !isResolvedOrRejected) {
                const snackbarKey = this.showSnackbar?.("loadingAutoLayoutIteration");

                // Close the snackbar when the promise is resolved or rejected
                renderIterationPromise.finally(() => {
                    this.closeSnackbar?.(snackbarKey);
                });
            }
        }, 500);

        renderIterationPromise
            .catch(() => {
                // Ideally the error should be caught upstream
                this.showSnackbar?.("genericError");
            })
            .finally(() => {
                isResolvedOrRejected = true;
            });
    }

    /**
     * @inheritdoc
     *
     * We use a PromiseQueue internally and each time we receive
     * an updated job from the server, we create a task to process the latest iterations and push
     * that task to the queue
     *
     * Key function to set local `pcbEditorUiStore` with the latest iteration data.
     *
     * The function should ideally be wrapped around a try-catch block upstream.
     *
     * 1. Fetch the board data from GCS
     * 2. Update the local `pcbEditorUiStore` with the latest iteration data
     * 3. (Optional) If the user is viewing the last iteration, update the current iteration index
     *
     * Note: We locally store N+1 iterations, where N is the number of iterations received from the backend.
     * And the 1 extra is the initial state of the board (the dummy iteration).
     */
    public async processIterations(job: StartedAutoLayoutJob): Promise<void> {
        const task = async () => {
            const receivedIterations = job.iterations;
            if (receivedIterations.length === 0) return;

            const {useAutoLayoutStore} = this;

            // Add a dummy iteration in the first position when we receive the first iteration
            {
                const existingIterationData = usePcbEditorUiStore.getState().autoLayoutIterationData;
                const isFirstIteration =
                    existingIterationData === undefined || existingIterationData.iterations.length === 0;
                if (isFirstIteration) {
                    usePcbEditorUiStore.getState().addAutoLayoutIterationData(DUMMY_ITERATION);
                    usePcbEditorUiStore.getState().setAutoLayoutCurrentIterationIndex(0);

                    useAutoLayoutStore.getState().setSliderPosition(0);
                }
            }

            let restoreToLocalIndex: number | undefined;
            // If the local sliderPosition is not set yet, and we see a previously
            // selected iteration hash in the job, restore local slider position
            // to point to that iteration first
            if (
                !useAutoLayoutStore.getState().sliderPosition &&
                job.selectedIterationHash &&
                // TODO: Can remove this once latest code/schema of `selectedIterationHash` is deployed to server, because it
                // was once default to DUMMY_ITERATION_HASH and got deployed
                job.selectedIterationHash !== DUMMY_ITERATION_HASH
            ) {
                restoreToLocalIndex = receivedIterations.findIndex(
                    (iteration) => iteration.hash === job.selectedIterationHash,
                );

                if (restoreToLocalIndex >= 0) {
                    restoreToLocalIndex += 1;
                    useAutoLayoutStore.getState().setSliderPosition(restoreToLocalIndex);
                }
            }

            /**
             * Assuming iterations are ordered, iterate through iterations and add corresponding entry
             * to the store.
             *
             * Also, update slider position by optionally stick it to the end if it's already pointing at the last iteration
             */
            for (let index = 0; index < receivedIterations.length; index++) {
                // Using structuredClone to avoid reading mutated data
                const existingIterationData = structuredClone(usePcbEditorUiStore.getState().autoLayoutIterationData);
                const existingIterations = existingIterationData?.iterations ?? [];

                const localIterationIndex = index + 1; // +1 because of the dummy iteration
                const localIterationExists = !!existingIterations[localIterationIndex];

                const receivedIteration = receivedIterations[index]; // will ideally be defined
                if (!receivedIteration || localIterationExists) {
                    continue;
                }

                // Create an empty iteration client data, and add it to the store.
                // Empty means we haven't loaded the actual board yet, it only has the location
                // to load the board at this point
                const emptyIterationClientData = createAutoLayoutIterationClientData(receivedIteration);

                // Add the iteration data to the local store
                usePcbEditorUiStore.getState().addAutoLayoutIterationData(emptyIterationClientData);

                // Optionally stick slider to the end - only stick it to the end when it's already at the end
                // We only do this when we are not restoring to a previous selection
                if (
                    !restoreToLocalIndex &&
                    useAutoLayoutStore.getState().sliderPosition === existingIterations.length - 1
                ) {
                    useAutoLayoutStore.getState().setSliderPosition(localIterationIndex);
                }
            }

            // If we are selecting any iteration, render the iteration
            if (useAutoLayoutStore.getState().sliderPosition) {
                this.renderIteration(useAutoLayoutStore.getState().sliderPosition, false, false);
            }
        };
        await this.setAutoLayoutDataQueue.add(task);
    }

    // TODO: Combine this with same method in AutoLayoutService?
    private async forceRebake() {
        const {reduxStoreService, pcbBakedGoodsManager} = this;
        const document = reduxStoreService.getStore().getState().document;
        if (document) {
            try {
                await pcbBakedGoodsManager.reload(document, document.pcbLayoutNodes, document.pcbLayoutRuleSets);
            } catch (error) {
                this.logger.error("Error in force rebake", error);
            }
        }
    }
}
