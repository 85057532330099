/* eslint-disable @typescript-eslint/no-var-requires */
import {getFirebaseEnvironment} from "@buildwithflux/constants";
import {
    CachedDocumentEventRepository,
    CommandPersistenceService,
    createImmerPatchAdapter,
    createPartLibrary,
    createSnapshotLibrary,
    PcbPrimitiveStore,
    PcbTracingGraph,
} from "@buildwithflux/core";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    ClientFirestoreAdapter,
    createClientConverters,
    FirestoreCache,
    getFirebaseCompatApp,
    getFirebaseConfigForCurrentEnvironment,
    getFirebaseConfigForSpecificEnvironment,
    MetadataGenerator,
} from "@buildwithflux/firestore-compatibility-layer";
import {OrganizationUid, StripeClientMode, userJobParsers, UserUid} from "@buildwithflux/models";
import {PlanEntitlementService} from "@buildwithflux/plans";
import {
    ClientIdProvider,
    createFirestorePartRepository,
    createFirestorePartVersionRepository,
    createFirestoreStarredDocumentRepository,
    DocumentSnapshotRepository,
    FirestoreAccountFollowingRepository,
    FirestoreActionRecordRepository,
    FirestoreAutoLayoutJobMetadataRepository,
    FirestoreAutoLayoutJobRepository,
    FirestoreChatRepository,
    FirestoreCommentRepository,
    FirestoreDocumentPricingRepository,
    FirestoreDocumentRepository,
    FirestoreEnterpriseRepository,
    FirestoreHandleRepository,
    FirestoreOrganizationPrivateMetadataRepository,
    FirestoreOrganizationRepository,
    FirestoreReviewCommentRepository,
    FirestoreReviewRepository,
    FirestoreSuggestionRepository,
    FirestoreUserAnalyticsRepository,
    FirestoreUserJobRepository,
    FirestoreUserPrivateMetadataRepository,
    FirestoreUserRepository,
    FirestoreUserUiStateRepository,
    StaticPartVersionRepository,
} from "@buildwithflux/repositories";
import {APP_VERSION, areWeTestingWithJest, defaultCdnConfig, silentLogger} from "@buildwithflux/shared";
import {createDocumentService} from "@buildwithflux/solder-core";
import axios from "axios";
import {connectAuthEmulator, getAuth} from "firebase/auth";

import {createCopilotChatStoreHook} from "../components/pages/document/components/editor/components/right_drawer/copilot_chat/state/useCopilotChatStore";
import {FileOrganizationStorageHelper} from "../modules/account";
import {createAccountInformationStoreHook} from "../modules/account/AccountInformationStore";
import {FirebaseAuthService, FirebaseCurrentUserService, SignUpService} from "../modules/auth";
import {TrialReactivationService} from "../modules/auth/services/trialReactivation";
import {createAlgoliaSearchClientStoreHook} from "../modules/auth/state/algoliaClient";
import {createCurrentUserStoreHook} from "../modules/auth/state/currentUser";
import {createOrganizationStoreHook} from "../modules/auth/state/organization";
import {createTypesenseSearchClientStoreHook} from "../modules/auth/state/typesenseClient";
import {createUserStoreHook} from "../modules/auth/state/user";
import {DeepPcbBoardFetcher} from "../modules/auto_layout";
import {DeepPcbAutoLayoutIterationProcessor} from "../modules/auto_layout/services/DeepPcbAutoLayoutIterationProcessor";
import {DeepPcbAutoLayoutService} from "../modules/auto_layout/services/DeepPcbAutoLayoutService";
import {createAutoLayoutStoreHook} from "../modules/auto_layout/state";
import {ClipboardService} from "../modules/clipboard/ClipboardService";
import {createUseRemoteUserIsTypingStateHook} from "../modules/comments/remoteUserTypingState";
import {createUseCommentStoreHook} from "../modules/comments/store";
import {PcbConnectivityGraph} from "../modules/connectivity/PcbConnectivityGraph";
import {createFeatureFlagsStoreHook} from "../modules/feature_flags/FeatureFlagsStore";
import {LaunchDarklyService} from "../modules/feature_flags/LaunchDarklyService";
import {DocumentObserver} from "../modules/observers/DocumentObserver";
import {createOnboardingDialogStoreHook} from "../modules/onboarding/store";
import {createCreditReportStoreHook} from "../modules/payments/state/credit";
import {createPaymentUrlStoreHook} from "../modules/payments/state/url";
import {createPCBStackupModalStore} from "../modules/pcb_stackup/store";
import {ClientPricingService} from "../modules/pricing";
import {FirestoreProfileHelper} from "../modules/profile";
import {
    createReactivitySubscriptions,
    createUseAllPcbNodes,
    createUseBaseDocument,
    createUsePcbNode,
    createUsePcbNodeBakedRules,
} from "../modules/reactivity";
import {createReviewStore} from "../modules/review/store";
import {
    FirestoreAxiosDocumentEventRepository,
    getSolderAxiosDefaults,
} from "../modules/solder/FirestoreAxiosDocumentEventRepository";
import {AnalyticsStorage} from "../modules/storage_engine/AnalyticsStorage";
import {BrowserStaticPartVersionStorageAdapter} from "../modules/storage_engine/cdn";
import {BrowserDocumentSnapshotStorageAdapter} from "../modules/storage_engine/cdn/BrowserDocumentSnapshotStorageAdapter";
import {NoOpDocumentSnapshotStorageAdapter} from "../modules/storage_engine/cdn/NoOpDocumentSnapshotStorageAdapter";
import {NoOpStaticPartVersionStorageAdapter} from "../modules/storage_engine/cdn/NoOpStaticPartVersionStorageAdapter";
import {CommentStorage} from "../modules/storage_engine/CommentStorage";
import AlgoliaConnector from "../modules/storage_engine/connectors/AlgoliaConnector";
import {EnvironmentSwitcher} from "../modules/storage_engine/connectors/EnvironmentSwitcher";
import {TypesenseConnector} from "../modules/storage_engine/connectors/TypesenseConnector";
import {FirestoreDocumentStorage} from "../modules/storage_engine/DocumentStorage";
import {S3FileStorage} from "../modules/storage_engine/FileStorage";
import {AnalyticsEventHelper} from "../modules/storage_engine/helpers/AnalyticsEventHelper";
import {DocumentStorageHelper} from "../modules/storage_engine/helpers/DocumentStorageHelper";
import {PartStorageHelper} from "../modules/storage_engine/helpers/PartStorageHelper";
import {OrganizationProfileUpdater, UserProfileUpdater} from "../modules/storage_engine/helpers/ProfileUpdater";
import {UserStorageHelper} from "../modules/storage_engine/helpers/UserStorageHelper";
import {LRULocalStorage} from "../modules/storage_engine/LRULocalStorage";
import {PartStorage} from "../modules/storage_engine/PartStorage";
import {ProjectInviteStorage} from "../modules/storage_engine/ProjectInviteStorage";
import {UserStorage} from "../modules/storage_engine/UserStorage";
import {PcbBakedGoodsManager} from "../modules/stores/pcb/PcbBakedGoodsManager";
import {createPersistedDocumentUiStoreHook} from "../modules/stores/ui/persistedStore";
import {createUseDocumentUiStoreHook} from "../modules/stores/ui/store";
import {ClientSuggestionService, ReduxAdapter} from "../modules/suggestions";
import {changeCaptureUnitOfWorkCreator} from "../modules/unit_of_work/ChangeCaptureUnitOfWork";
import {createUserUiStateStoreHook} from "../modules/user_ui_states/store";
import {UserCodeRuntimeWrapper} from "../modules/usercode_runtime/UserCodeRuntimeWrapper";
import {createReduxSolderAdapter} from "../redux/adapters/solder";
import {ReduxStoreServiceImpl} from "../redux/util/service";

import type {ContainerBindings} from "./container";
import {connectToFirebaseFunctions} from "./firebaseFunctions";

/**
 * Now we define a recipe for how to instantiate each container service
 */
export const defaultBindings: ContainerBindings = {
    shutdown(services) {
        return async () => {
            if ("logger" in services) {
                services.logger.debug("Beginning container shutdown");
            }

            if ("useOnboardingDialogStore" in services) {
                services.useOnboardingDialogStore.getState().shutdown?.();
            }

            const toShutDown = [
                "algoliaConnector",
                "typesenseConnector",
                "signUpService",
                "currentUserService",
                "environmentSwitcher",
                "launchDarklyService",
            ] as const;

            for (const service of toShutDown) {
                if (service in services) {
                    await services[service].shutdown?.();
                }
            }

            if ("firestoreForEmulator" in services) {
                await services.firestoreForEmulator.terminate();
            }

            if ("firestoreForCurrentEnvironment" in services) {
                await services.firestoreForCurrentEnvironment.terminate();
            }

            if ("firebaseAppForEmulator" in services) {
                await services.firebaseAppForEmulator.delete(); // This only renders this instance unusable
            }

            if ("firebaseAppForCurrentEnvironment" in services) {
                await services.firebaseAppForCurrentEnvironment.delete(); // This only renders this instance unusable
            }

            const shutDown = services as Record<string, unknown>;
            delete shutDown.algoliaConnector;
            delete shutDown.useOnboardingDialogStore;
            delete shutDown.currentUserService;
            delete shutDown.firestoreForEmulator;
            delete shutDown.firestoreForCurrentEnvironment;
            delete shutDown.firebaseAppForEmulator;
            delete shutDown.firebaseAppForCurrentEnvironment;
            delete shutDown.featureFlags;
        };
    },

    // Firebase
    firebaseAuth(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firebaseAuthForEmulator
            : services.firebaseAuthForCurrentEnvironment;
    },
    firebaseAuthForEmulator({firebaseAppForEmulator}) {
        const connection = getAuth(firebaseAppForEmulator);
        connectAuthEmulator(connection, "http://127.0.0.1:9099");
        return connection;
    },
    firebaseAuthForCurrentEnvironment({firebaseAppForCurrentEnvironment}) {
        return getAuth(firebaseAppForCurrentEnvironment);
    },
    firebaseAppConfig() {
        return getFirebaseConfigForCurrentEnvironment();
    },
    firebaseApp(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firebaseAppForEmulator
            : services.firebaseAppForCurrentEnvironment;
    },
    firebaseAppForEmulator() {
        const config = getFirebaseConfigForSpecificEnvironment("emulated");
        return getFirebaseCompatApp(config, config.projectId);
    },
    firebaseAppForCurrentEnvironment({firebaseAppConfig}) {
        return getFirebaseCompatApp(firebaseAppConfig, firebaseAppConfig.projectId);
    },
    firestore(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firestoreForEmulator
            : services.firestoreForCurrentEnvironment;
    },
    firestoreForEmulator({firebaseAppForEmulator, firestoreSettings}) {
        const firestore = firebaseAppForEmulator.firestore();

        firestore.useEmulator("127.0.0.1", 8080);

        firestore.settings({
            // This is important to avoid runtime errors on whenever a property is
            // undefined. Previously, we ran all data thru a recursive function
            // removeUndefinedFields. The same setting is the common convention in
            // our cloud functions.
            ignoreUndefinedProperties: true,
            merge: true,

            ...firestoreSettings,
        });

        return firestore;
    },
    firestoreForCurrentEnvironment({firebaseAppForCurrentEnvironment, firestoreSettings}) {
        const firestore = firebaseAppForCurrentEnvironment.firestore();

        firestore.settings({
            // This is important to avoid runtime errors on whenever a property is
            // undefined. Previously, we ran all data thru a recursive function
            // removeUndefinedFields. The same setting is the common convention in
            // our cloud functions.
            ignoreUndefinedProperties: true,
            merge: true,

            ...firestoreSettings,
        });

        return firestore;
    },
    firestoreSettings() {
        return {
            experimentalAutoDetectLongPolling: true,
        }; // note: this binding is overridden in tests
    },
    firestoreConverters({metadataGenerator}) {
        return createClientConverters(metadataGenerator);
    },
    functionsCentral(services) {
        return connectToFirebaseFunctions(services, "us-central1", getFirebaseEnvironment() === "emulated");
    },
    functionsWest(services) {
        return connectToFirebaseFunctions(services, "us-west2", getFirebaseEnvironment() === "emulated");
    },
    functionsWest1(services) {
        return connectToFirebaseFunctions(services, "us-west1", getFirebaseEnvironment() === "emulated");
    },

    // Auth stuff
    firebaseAuthService({firebaseAuth}) {
        return new FirebaseAuthService(firebaseAuth);
    },
    currentUserService({firebaseAuthService, userRepository, userPrivateMetadataRepository}) {
        return new FirebaseCurrentUserService(
            firebaseAuthService,
            userRepository,
            userPrivateMetadataRepository,
            silentLogger,
        );
    },
    algoliaConnector({functionsAdapter, currentUserService, logger}) {
        return new AlgoliaConnector(functionsAdapter, currentUserService, logger);
    },
    typesenseConnector({functionsAdapter, currentUserService, logger}) {
        return new TypesenseConnector(functionsAdapter, currentUserService, logger);
    },
    signUpService({firebaseAuthService, firestoreAdapter, logger, useFeatureFlagsStore}) {
        return new SignUpService(firebaseAuthService, firestoreAdapter, useFeatureFlagsStore, logger);
    },
    trialReactivationService({currentUserService, functionsAdapter, planEntitlement, analyticsStorage, logger}) {
        return new TrialReactivationService(
            currentUserService,
            functionsAdapter,
            planEntitlement,
            analyticsStorage,
            logger,
            StripeClientMode.enum.live,
        );
    },

    // Adapters
    firestoreAdapter({firestore, metadataGenerator, firestoreConverters, logger}) {
        return new ClientFirestoreAdapter(firestore, metadataGenerator, firestoreConverters, logger);
    },
    functionsAdapter({functionsCentral, functionsWest, functionsWest1}) {
        return new ClientFunctionsAdapter(functionsCentral, functionsWest, functionsWest1);
    },
    reduxSolderAdapter() {
        return createReduxSolderAdapter();
    },
    /**
     * Only connect to the real live CDN if we're not currently testing.
     */
    staticPartVersionStorageAdapter(services) {
        if (areWeTestingWithJest()) {
            return new NoOpStaticPartVersionStorageAdapter();
        } else {
            return new BrowserStaticPartVersionStorageAdapter(defaultCdnConfig, services.firebaseAuth);
        }
    },
    documentSnapshotStorageAdapter(services) {
        if (areWeTestingWithJest()) {
            return new NoOpDocumentSnapshotStorageAdapter();
        } else {
            return new BrowserDocumentSnapshotStorageAdapter(defaultCdnConfig, services.firebaseAuth);
        }
    },

    // Layout
    pcbBakedGoodsManager({
        logger,
        pcbPrimitiveStore,
        documentService,
        pcbConnectivityGraph,
        pcbTracingGraph,
        useFeatureFlagsStore,
        reduxStoreService,
    }) {
        return new PcbBakedGoodsManager(
            documentService,
            pcbPrimitiveStore,
            pcbConnectivityGraph,
            pcbTracingGraph,
            reduxStoreService,
            useFeatureFlagsStore,
            logger,
        );
    },

    // Suggestions
    suggestionService({logger, reduxStoreService, suggestionRepository, currentUserService, clientIdProvider}) {
        const reduxAdapter = new ReduxAdapter(reduxStoreService);
        return new ClientSuggestionService(
            suggestionRepository,
            reduxAdapter,
            currentUserService,
            clientIdProvider,
            logger,
        );
    },

    // Document
    documentService({commandPersistenceService, documentEventRepository, cachedDocumentEventRepository}) {
        return createDocumentService(
            commandPersistenceService,
            documentEventRepository,
            cachedDocumentEventRepository,
            changeCaptureUnitOfWorkCreator,
        );
    },
    cachedDocumentEventRepository({documentEventRepository}) {
        return new CachedDocumentEventRepository(documentEventRepository);
    },
    commandPersistenceService({documentEventRepository, cachedDocumentEventRepository}) {
        return new CommandPersistenceService(documentEventRepository, cachedDocumentEventRepository);
    },

    // Reactivity
    reactivitySubscriptions({documentService}) {
        return createReactivitySubscriptions(documentService);
    },

    // Repos
    autoLayoutJobMetadataRepository({firestoreAdapter, logger}) {
        return new FirestoreAutoLayoutJobMetadataRepository(firestoreAdapter, logger);
    },
    autoLayoutJobRepository({firestoreAdapter, logger}) {
        return new FirestoreAutoLayoutJobRepository(firestoreAdapter, logger);
    },
    actionRecordRepository({firestoreAdapter, metadataGenerator, clientIdProvider}) {
        return new FirestoreActionRecordRepository(firestoreAdapter, metadataGenerator, clientIdProvider);
    },
    commentRepository({firestoreAdapter}) {
        return new FirestoreCommentRepository(firestoreAdapter);
    },
    chatRepository({firestoreAdapter}) {
        return new FirestoreChatRepository(firestoreAdapter);
    },
    userUiStateRepository({firestoreAdapter}) {
        return new FirestoreUserUiStateRepository(firestoreAdapter);
    },
    documentRepository({firestoreAdapter, functionsAdapter, logger}) {
        return new FirestoreDocumentRepository(firestoreAdapter, functionsAdapter, logger);
    },
    documentPricingRepository({firestoreAdapter, logger}) {
        return new FirestoreDocumentPricingRepository(firestoreAdapter, logger);
    },
    organizationRepository({firestoreAdapter, userRepository, organizationPrivateMetadataRepository, logger}) {
        return new FirestoreOrganizationRepository(
            firestoreAdapter,
            userRepository,
            organizationPrivateMetadataRepository,
            logger,
        );
    },
    organizationPrivateMetadataRepository({firestoreAdapter, logger}) {
        return new FirestoreOrganizationPrivateMetadataRepository(firestoreAdapter, logger);
    },
    partRepository({firestoreAdapter}) {
        return createFirestorePartRepository(firestoreAdapter);
    },
    reviewRepository({firestoreAdapter}) {
        return new FirestoreReviewRepository(firestoreAdapter);
    },
    reviewCommentRepository({firestoreAdapter}) {
        return new FirestoreReviewCommentRepository(firestoreAdapter);
    },
    partVersionRepository({firestoreAdapter}) {
        return createFirestorePartVersionRepository(firestoreAdapter);
    },
    starredDocumentRepository({firestoreAdapter, documentRepository}) {
        return createFirestoreStarredDocumentRepository(firestoreAdapter, documentRepository);
    },
    accountFollowingRepository({firestoreAdapter}) {
        return new FirestoreAccountFollowingRepository(firestoreAdapter);
    },
    staticPartVersionRepository({staticPartVersionStorageAdapter}) {
        return new StaticPartVersionRepository(staticPartVersionStorageAdapter);
    },
    documentSnapshotRepository({documentSnapshotStorageAdapter}) {
        return new DocumentSnapshotRepository(documentSnapshotStorageAdapter);
    },
    suggestionRepository({firestoreAdapter}) {
        return new FirestoreSuggestionRepository(firestoreAdapter);
    },
    userAnalyticsRepository({firestoreAdapter}) {
        return new FirestoreUserAnalyticsRepository(firestoreAdapter);
    },
    userJobRepository({firestoreAdapter, logger}) {
        return new FirestoreUserJobRepository(firestoreAdapter, logger, userJobParsers);
    },
    documentEventRepository({firestoreAdapter, firebaseAuth, logger}) {
        const axiosInstance = axios.create(getSolderAxiosDefaults());
        return new FirestoreAxiosDocumentEventRepository(firestoreAdapter, axiosInstance, firebaseAuth, logger);
    },

    // Storage
    analyticsStorage({
        reduxStoreService,
        currentUserService,
        userAnalyticsRepository,
        useOrganizationStore,
        documentService,
        usePersistedDocumentUiStore,
        useFeatureFlagsStore,
        logger,
    }) {
        return new AnalyticsStorage(
            reduxStoreService,
            currentUserService,
            userAnalyticsRepository,
            useOrganizationStore,
            documentService,
            usePersistedDocumentUiStore,
            useFeatureFlagsStore,
            logger,
        );
    },
    commentStorage({firestore, firestoreAdapter, logger, clientIdProvider}) {
        return new CommentStorage(firestore, firestoreAdapter, logger, clientIdProvider);
    },
    documentStorage({
        documentRepository,
        logger,
        functionsAdapter,
        analyticsStorage,
        firestore,
        firestoreAdapter,
        clientIdProvider,
        snapshotLibrary,
        partStorage,
        useFeatureFlagsStore,
    }) {
        return new FirestoreDocumentStorage(
            documentRepository,
            logger,
            functionsAdapter,
            analyticsStorage,
            firestore,
            firestoreAdapter,
            clientIdProvider,
            snapshotLibrary,
            partStorage,
            useFeatureFlagsStore,
        );
    },
    enterpriseRepository({firestoreAdapter, logger}) {
        return new FirestoreEnterpriseRepository(firestoreAdapter, logger);
    },
    fileStorage({analyticsStorage, functionsAdapter}) {
        return new S3FileStorage({}, analyticsStorage, functionsAdapter);
    },
    metadataGenerator({clientIdProvider}) {
        return new MetadataGenerator(APP_VERSION, clientIdProvider.clientId, "client");
    },
    partLibrary({partRepository, partVersionRepository, staticPartVersionRepository, documentRepository}) {
        return createPartLibrary(
            partRepository,
            partVersionRepository,
            staticPartVersionRepository,
            documentRepository,
        );
    },
    snapshotLibrary({documentSnapshotRepository, logger}) {
        return createSnapshotLibrary(documentSnapshotRepository, logger);
    },
    partStorage({partRepository, documentRepository, partVersionRepository, analyticsStorage, logger, partLibrary}) {
        return new PartStorage(
            partLibrary,
            documentRepository,
            partRepository,
            partVersionRepository,
            analyticsStorage,
            logger,
        );
    },
    projectInviteStorage({firestore, firestoreAdapter, logger, clientIdProvider}) {
        return new ProjectInviteStorage(firestore, firestoreAdapter, logger, clientIdProvider);
    },
    userStorage({firestore, firestoreAdapter, logger, analyticsStorage, clientIdProvider}) {
        return new UserStorage(firestore, firestoreAdapter, logger, analyticsStorage, clientIdProvider);
    },
    userRepository({firestoreAdapter, logger}) {
        return new FirestoreUserRepository(firestoreAdapter, logger);
    },
    handleRepository({firestoreAdapter}) {
        return new FirestoreHandleRepository(firestoreAdapter);
    },
    userPrivateMetadataRepository({firestoreAdapter}) {
        return new FirestoreUserPrivateMetadataRepository(firestoreAdapter);
    },
    lruLocalStorage() {
        return new LRULocalStorage();
    },

    // Plans
    planEntitlement({logger}) {
        return new PlanEntitlementService(logger);
    },

    // Auto Layout
    autoLayoutService({
        reduxStoreService,
        functionsAdapter,
        currentUserService,
        pcbPrimitiveStore,
        autoLayoutJobMetadataRepository,
        autoLayoutJobRepository,
        documentService,
        analyticsStorage,
        pcbBakedGoodsManager,
        pcbTracingGraph,
        useAutoLayoutStore,
        autoLayoutIterationProcessor,
        logger,
    }) {
        return new DeepPcbAutoLayoutService(
            reduxStoreService,
            functionsAdapter,
            currentUserService,
            pcbPrimitiveStore,
            autoLayoutJobRepository,
            autoLayoutJobMetadataRepository,
            documentService,
            pcbBakedGoodsManager,
            useAutoLayoutStore,
            autoLayoutIterationProcessor,
            analyticsStorage,
            pcbTracingGraph,
            logger,
        );
    },
    autoLayoutIterationProcessor({
        documentService,
        autoLayoutBoardFetcher,
        reduxStoreService,
        pcbBakedGoodsManager,
        analyticsStorage,
        useAutoLayoutStore,
        logger,
    }) {
        return new DeepPcbAutoLayoutIterationProcessor(
            reduxStoreService,
            pcbBakedGoodsManager,
            documentService,
            autoLayoutBoardFetcher,
            analyticsStorage,
            useAutoLayoutStore,
            logger,
        );
    },
    autoLayoutBoardFetcher({logger}) {
        return new DeepPcbBoardFetcher(logger);
    },

    // Helpers
    pcbPrimitiveStore() {
        return new PcbPrimitiveStore();
    },
    pcbConnectivityGraph() {
        return new PcbConnectivityGraph();
    },
    pcbTracingGraph() {
        return new PcbTracingGraph();
    },
    immerPatchAdapter() {
        return createImmerPatchAdapter();
    },
    analyticsEventHelper({analyticsStorage}) {
        return new AnalyticsEventHelper(analyticsStorage);
    },
    partStorageHelper({fileStorage}) {
        return new PartStorageHelper(fileStorage);
    },
    userCodeRuntimeWrapper() {
        return new UserCodeRuntimeWrapper();
    },
    userStorageHelper({fileStorage}) {
        return new UserStorageHelper(fileStorage);
    },
    documentStorageHelper({fileStorage}) {
        return new DocumentStorageHelper(fileStorage);
    },
    organizationStorageHelper({fileStorage}) {
        return new FileOrganizationStorageHelper(fileStorage);
    },
    profileHelper({handleRepository, userRepository, organizationRepository, functionsAdapter, logger}) {
        return new FirestoreProfileHelper(
            handleRepository,
            userRepository,
            organizationRepository,
            functionsAdapter,
            logger,
        );
    },
    userProfileUpdater({userStorage, currentUserService, logger}) {
        return (userUid: UserUid) => new UserProfileUpdater(userUid, userStorage, currentUserService, logger);
    },
    organizationProfileUpdater({organizationRepository, currentUserService, logger}) {
        return (organizationUid: OrganizationUid) =>
            new OrganizationProfileUpdater(organizationUid, organizationRepository, currentUserService, logger);
    },
    documentObserver({reduxStoreService, documentService, immerPatchAdapter}) {
        return new DocumentObserver(reduxStoreService, documentService, immerPatchAdapter);
    },

    // Store hook factories
    useAccountInformationStore({userRepository, organizationRepository, enterpriseRepository}) {
        return createAccountInformationStoreHook(userRepository, organizationRepository, enterpriseRepository);
    },
    useUserStore({currentUserService, analyticsStorage, userStorage}) {
        return createUserStoreHook(currentUserService, analyticsStorage, userStorage);
    },
    useCurrentUserStore({currentUserService, firebaseAuthService}) {
        return createCurrentUserStoreHook(currentUserService, firebaseAuthService, silentLogger);
    },
    useAlgoliaSearchClientStore({algoliaConnector}) {
        return createAlgoliaSearchClientStoreHook(algoliaConnector);
    },
    useTypesenseSearchClientStore({typesenseConnector}) {
        return createTypesenseSearchClientStoreHook(typesenseConnector);
    },
    useCommentStore({currentUserService, commentRepository, logger}) {
        return createUseCommentStoreHook(currentUserService, commentRepository, logger);
    },
    useUserUiStateStore({currentUserService, userUiStateRepository, useFeatureFlagsStore, logger}) {
        return createUserUiStateStoreHook(currentUserService, userUiStateRepository, useFeatureFlagsStore, logger);
    },
    useRemoteUserIsTypingState(_services) {
        return createUseRemoteUserIsTypingStateHook();
    },
    useDocumentUiStore(_services) {
        return createUseDocumentUiStoreHook();
    },
    usePersistedDocumentUiStore(_services) {
        return createPersistedDocumentUiStoreHook();
    },
    useOnboardingDialogStore({
        currentUserService,
        userRepository,
        userPrivateMetadataRepository,
        analyticsStorage,
        functionsAdapter,
        useFeatureFlagsStore,
    }) {
        return createOnboardingDialogStoreHook({
            currentUserService,
            userRepository,
            userPrivateMetadataRepository,
            analyticsStorage,
            functionsAdapter,
            featureFlagsStore: useFeatureFlagsStore,
            logger: silentLogger,
        });
    },
    useOrganizationStore({currentUserService, organizationRepository, enterpriseRepository, functionsAdapter}) {
        return createOrganizationStoreHook(
            currentUserService,
            organizationRepository,
            enterpriseRepository,
            functionsAdapter,
            silentLogger,
        );
    },
    useCopilotChatStore({currentUserService, chatRepository, documentService}) {
        return createCopilotChatStoreHook(currentUserService, documentService, chatRepository, silentLogger);
    },
    usePaymentUrlStore({functionsAdapter, logger}) {
        return createPaymentUrlStoreHook(functionsAdapter, logger);
    },
    usePcbNode({reactivitySubscriptions}) {
        return createUsePcbNode(reactivitySubscriptions);
    },
    usePcbNodeBakedRules({reactivitySubscriptions}) {
        return createUsePcbNodeBakedRules(reactivitySubscriptions);
    },
    useAllPcbNodes({reactivitySubscriptions}) {
        return createUseAllPcbNodes(reactivitySubscriptions);
    },
    useBaseDocument({reactivitySubscriptions, reduxStoreService}) {
        return createUseBaseDocument(reactivitySubscriptions, reduxStoreService);
    },
    useCreditReportStore({functionsAdapter, currentUserService, logger}) {
        return createCreditReportStoreHook(functionsAdapter, currentUserService, logger, silentLogger);
    },
    useReviewStore(_services) {
        return createReviewStore();
    },
    usePCBStackupStore() {
        return createPCBStackupModalStore();
    },
    useAutoLayoutStore() {
        return createAutoLayoutStoreHook();
    },
    useFeatureFlagsStore({launchDarklyService}) {
        return createFeatureFlagsStoreHook(launchDarklyService);
    },

    // Other
    logger() {
        return console;
    },
    reduxStoreService() {
        return new ReduxStoreServiceImpl();
    },
    clipboardService() {
        return new ClipboardService();
    },
    environmentSwitcher({analyticsStorage, useFeatureFlagsStore}) {
        return new EnvironmentSwitcher(analyticsStorage, useFeatureFlagsStore);
    },
    launchDarklyService(services) {
        return new LaunchDarklyService(
            () => services.currentUserService,
            () => services.useOrganizationStore,
            services.logger,
        );
    },

    // Misc
    clientIdProvider() {
        return new ClientIdProvider();
    },

    pricingDataCache({firestoreAdapter}) {
        return new FirestoreCache(firestoreAdapter.pricingDataCacheCollection());
    },

    pricingService({pricingDataCache, functionsAdapter, analyticsStorage, logger}) {
        return new ClientPricingService(pricingDataCache, functionsAdapter, analyticsStorage, logger);
    },
};
