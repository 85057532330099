import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {CurrentUser, IUserData, SearchAuthContext} from "@buildwithflux/models";
import {Logger, Unsubscriber, areWeTestingWithJest, guid} from "@buildwithflux/shared";
import {cloneDeep} from "lodash";
import {Client} from "typesense";

import {CurrentUserService} from "../../auth";
import {useTypesenseSearchClient} from "../../auth/state/typesenseClient";

type SearchClientChangeHandler = (searchClient: Client | undefined) => void;

export class TypesenseConnector {
    private searchClient: Client | undefined;

    protected authContext: SearchAuthContext = {};

    private _scopedSearchKey: string | undefined;

    // Use this to support subscription to search client change
    protected readonly subscriptionHandles: Record<string, SearchClientChangeHandler> = {};

    protected readonly userChangeUnsub: Unsubscriber | undefined = undefined;

    public readonly useSearchClient: () => TypesenseConnector["searchClient"];
    constructor(
        protected readonly functionsAdapter: FunctionsAdapter,
        protected readonly currentUserService: CurrentUserService,
        protected readonly logger: Logger,
    ) {
        this.useSearchClient = useTypesenseSearchClient;
        this.userChangeUnsub = this.currentUserService.subscribeToUserChanges(this.onCurrentUserChanged);
    }

    /**
     * Clear the current search client's cache.
     */
    public clearCache() {
        // TODO: No-op for typesense, see if we can clear cache, but need to turn on server side cache first
    }

    public shutdown(): void {}

    /**
     * Get the current Typesense scoped search key.
     */
    public get scopedSearchKey(): string | undefined {
        return this._scopedSearchKey;
    }

    /**
     * Set a new Typesense scopedSearchKey key. This will cause the search client to be
     * re-initialized with the given key.
     */
    public set scopedSearchKey(value: string | undefined) {
        if (this.scopedSearchKey === value) {
            return;
        }

        this._scopedSearchKey = value;

        if (this.scopedSearchKey) {
            this.searchClient = this.makeSearchClient();
        } else {
            this.searchClient = undefined;
        }

        this.notifySearchClientListeners();
    }

    /**
     * Get the current Typesense search client. Avoid using this in React
     * components, as the search client may be reinitialized any time there is
     * a change to the current user or current auth context.
     */
    public getCurrentSearchClient(): TypesenseConnector["searchClient"] {
        return this.searchClient;
    }

    /**
     * Used by the zustand store defined in frontend/src/modules/auth/state/algoliaClient.ts
     */
    public subscribeToSearchClientChanges(callback: SearchClientChangeHandler): Unsubscriber {
        const subscriberId = guid();
        this.subscriptionHandles[subscriberId] = callback;
        return () => {
            this.unsubscribeFromSearchClientChanges(subscriberId);
        };
    }

    public unsubscribeFromSearchClientChanges(subscriberId: string): void {
        delete this.subscriptionHandles[subscriberId];
    }

    public notifySearchClientListeners(): void {
        Object.values(this.subscriptionHandles).forEach((callback) => callback(this.searchClient));
    }

    protected makeSearchClient(): Client {
        if (!this.scopedSearchKey) {
            throw new Error("Typesense scopedSearchKey not set");
        }

        if (!process.env.REACT_APP_TYPESENSE_HOST) {
            throw new Error("REACT_APP_TYPESENSE_HOST is not set");
        }

        return new Client({
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_HOST,
                    port: 443,
                    protocol: "https",
                },
            ],
            apiKey: this.scopedSearchKey,
            cacheSearchResultsForSeconds: 3600 * 24,
            // TODO: Check the best timeout value here
            connectionTimeoutSeconds: 20,
        });
    }

    /**
     * Set the current Algolia auth context. This will cause a new public
     * key to be minted and a new search client to be initialized if necessary.
     */
    public async setAuthContext(authContext: SearchAuthContext) {
        this.authContext = cloneDeep(authContext);

        // NOTE: even if the auth context is still {} (i.e. unchanged), rerun
        // the key generation to account for user change.
        // QUESTION: why?
        await this.updateScopedSearchKeyForUser(this.currentUserService.getCurrentUser());
    }

    protected async updateScopedSearchKeyForUser(user: IUserData | undefined): Promise<void> {
        // Same notes copied from AlgoliaConnector
        // HACK: This is bad and wrong.  However, something is going wrong in our unit tests where this is getting
        // called when it's not supposed to be and causing a firebase/deadline-exceeded error.  I'm taking a shortcut
        // for now because that error is uncaught and extremely difficult to track down... and it's actually harmless.
        // With launch looming, this is the tradeoff I'm making.
        if (areWeTestingWithJest()) {
            return;
        }

        this.logger.debug("Updating Typesense public key for user", {user, authContext: this.authContext});
        const result = await this.functionsAdapter.typesenseAuth(this.authContext);
        const {scopedSearchKey} = result.data;

        /*
         * The user may change _during_ a call to the algoliaAuth backend function
         * That means two requests for an algolia public key may be in-flight at once, and they may finish out of order
         * This approach discards any public keys that were for previously-set users
         */
        if (this.currentUserService.getCurrentUser()?.uid === user?.uid) {
            this.scopedSearchKey = scopedSearchKey;
        }
    }

    private onCurrentUserChanged = (currentUser: CurrentUser) => {
        void this.updateScopedSearchKeyForUser(currentUser.user);
    };
}
