import {CommentRepository, CommentUid, DocumentUid, ICommentThreadData, Message} from "@buildwithflux/core";
import {areWeTestingWithJest, isDevEnv, Logger, Unsubscriber} from "@buildwithflux/shared";
import {enableMapSet, produce} from "immer";
import {create, StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";

import {useFluxServices} from "../../injection/hooks";
import {CurrentUserService} from "../auth";

import {addComment, addThread, createThreadedMessages, removeComment, ThreadedMessages} from "./threadedMessages";

type Idle = {
    type: "idle";
    threadedMessages: ThreadedMessages;
};

type Listening = {
    type: "listening";
    listeningStartedAt: number;
    threadHasNewActivity: {[threadUid: ICommentThreadData["uid"]]: boolean};
    listeningToDocumentUid: string;
    commentUnsubscriber: Unsubscriber;
    threadUnsubscriber: Unsubscriber;
    threadedMessages: ThreadedMessages;
    commentContents: {[commentUid: string]: Message};
};

type CommentState = Idle | Listening;

type CommentApi = {
    /**
     * Acknowledge that a thread has been seen.
     */
    acknowledgeThreadActivity: (threadUid: ICommentThreadData["uid"]) => void;

    /**
     * Starts an asynchronous fetch for all comments for the document in question.  Induces a state transition
     * in the store from idle => listening if the store is idle.  If the store is currently listening, and the
     * document uid does not match the current document uid being listened to, this clears the store and restarts
     * the subscription with the new document uid.
     */
    prefetch: (documentUid: string) => void;

    /**
     * Sets specific comment data directly, and fakes the store into 'listening' mode
     *
     * Used for functional testing scenarios, such as Storybook, where we want to directly inject some static comment
     * state rather than load it from a data store
     */
    addTestCommentContents: (documentUid: DocumentUid, commentUid: CommentUid, comment: Message) => void;

    /**
     * Gracefully shut down the store, disposing all subscriptions.
     */
    shutdown: () => void;
};

type CommentStore = CommentState & CommentApi;
export type UseCommentStore = UseBoundStore<StoreApi<CommentStore>>;

export const createUseCommentStoreHook = (
    currentUserService: CurrentUserService,
    commentRepository: CommentRepository,
    logger: Logger,
): UseCommentStore =>
    create<CommentStore>()(
        devtools(
            (set, get) => {
                enableMapSet();
                return {
                    type: "idle",
                    threadedMessages: createThreadedMessages(),
                    acknowledgeThreadActivity: (threadUid) => {
                        set(
                            produce((state: CommentStore) => {
                                if (state.type === "listening") {
                                    state.threadHasNewActivity[threadUid] = false;
                                }
                            }),
                            false,
                            "acknowledgeThreadActivity",
                        );
                    },
                    prefetch: (documentUid: string) => {
                        const currentState = get();
                        // Proactively shutdown
                        currentState.shutdown();
                        const commentUnsubscriber = commentRepository.subscribeToAllComments(
                            documentUid,
                            (comments) => {
                                set(
                                    produce((state: CommentStore) => {
                                        for (const comment of comments) {
                                            addComment(state.threadedMessages, comment);
                                            // TODO: this is a little perverse
                                            if (state.type === "listening") {
                                                if (comment.owner_uid !== currentUserService.getCurrentUser()?.uid) {
                                                    if (comment.created_at > state.listeningStartedAt) {
                                                        const thread =
                                                            state.threadedMessages.threads[
                                                                comment.belongs_to_comment_thread_uid
                                                            ];
                                                        if (thread) {
                                                            if (thread.type === "messageThread") {
                                                                state.threadHasNewActivity[thread.threadUid] = true;
                                                            } else {
                                                                state.threadHasNewActivity[thread.parentThreadUid] =
                                                                    true;
                                                            }
                                                        }
                                                    }
                                                }
                                                state.commentContents[comment.uid] = comment;
                                            }
                                        }
                                    }),
                                    false,
                                    "onCommentReceive",
                                );
                            },
                            // Remove any deleted comments
                            (comments) => {
                                set(
                                    produce((state: CommentStore) => {
                                        for (const comment of comments) {
                                            if (state.type === "listening") {
                                                delete state.commentContents[comment.uid];
                                            }
                                            removeComment(state.threadedMessages, comment);
                                        }
                                    }),
                                    false,
                                    "onDeleteCommentReceive",
                                );
                            },
                            (_error) => {
                                logger.info(`gracefully shutting down comment subscriptions`);
                                get().shutdown();
                            },
                        );
                        const threadUnsubscriber = commentRepository.subscribeToAllThreads(
                            documentUid,
                            (threads) => {
                                set(
                                    produce((state: CommentStore) => {
                                        for (const thread of threads) {
                                            addThread(state.threadedMessages, thread);
                                        }
                                    }),
                                    false,
                                    "onThreadReceive",
                                );
                            },
                            (_error) => {
                                logger.info(`gracefully shutting down comment subscriptions`);
                                get().shutdown();
                            },
                        );
                        set(
                            produce((state: CommentStore) => ({
                                type: "listening",
                                threadHasNewActivity: {},
                                listeningStartedAt: Date.now(),
                                threadedMessages: createThreadedMessages(),
                                commentUnsubscriber,
                                threadUnsubscriber,
                                listeningToDocumentUid: documentUid,
                                prefetch: state.prefetch,
                                shutdown: state.shutdown,
                                acknowledgeThreadActivity: state.acknowledgeThreadActivity,
                                commentContents: {},
                            })),
                            false,
                            "prefetchStarted",
                        );
                    },
                    shutdown: () => {
                        const store = get();

                        // If the store is idle, there's nothing to do
                        if (store.type === "idle") {
                            return;
                        }

                        // Shut down the subscriptions
                        store.commentUnsubscriber();
                        store.threadUnsubscriber();

                        // Reset the state
                        set(
                            produce((state) => {
                                state.type = "idle";
                                state.listeningToDocumentUid = undefined;
                                state.threadedMessages = createThreadedMessages();
                            }),
                            false,
                            "onShutdown",
                        );
                    },
                    addTestCommentContents: (documentUid, commentUid, message) => {
                        set(
                            produce((state) => {
                                state.type = "listening";
                                state.listeningStartedAt = Date.now();
                                state.threadHasNewActivity = {};
                                if (!state.commentContents) {
                                    state.commentContents = {};
                                }
                                state.commentContents[commentUid] = message;
                                state.listeningToDocumentUid = documentUid;
                                state.commentUnsubscriber = () => {};
                                state.threadUnsubscriber = () => {};
                                state.threadedMessages = {
                                    threads: {},
                                    orphanedComments: {},
                                    orphanedThreads: {},
                                    namedThreadIndex: {},
                                };
                            }),
                        );
                    },
                };
            },
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "CommentStore"},
        ),
    );

function createDefaultThreadData(loading = false) {
    return {comments: [], replies: {}, threadUid: undefined, loading};
}

function getThreadData(threadUid: string, store: CommentStore) {
    const messages = store.threadedMessages.threads[threadUid];
    if (messages == null) {
        // A little weird but turns out, this can be considered the loading state for a thread -
        // at this point, the store does not have data available for the thread however, because getThreadData()
        // function is only currently called via comment pin click logic (that are present, before corresponding
        // thread message data is fetched), the fact that we're arriving at this point implies message data is
        // currently loading
        return createDefaultThreadData(true);
    }

    if (messages.type === "messageThread") {
        return {...messages, loading: false};
    } else {
        const parent = store.threadedMessages.threads[messages.parentThreadUid];
        if (!parent) {
            return createDefaultThreadData();
        }

        if (parent.type === "messageThreadPointer") {
            throw new Error(`runtime error - nested threads`);
        }

        return {...parent, loading: false};
    }
}

export function useMessagesForThread(threadUid: string) {
    return useFluxServices().useCommentStore((store) => {
        return getThreadData(threadUid, store);
    });
}

export function useMessagesForProjectChat() {
    return useFluxServices().useCommentStore((store) => {
        const projectChatThreadUid = store.threadedMessages.namedThreadIndex.projectChat;
        if (!projectChatThreadUid) {
            return createDefaultThreadData();
        } else {
            return getThreadData(projectChatThreadUid, store);
        }
    });
}

export function useProjectChatThreadUid() {
    return useFluxServices().useCommentStore((store) => store.threadedMessages.namedThreadIndex.projectChat);
}

export function useThreadHasNewActivity(threadUid: string) {
    return useFluxServices().useCommentStore((store) => {
        return [
            store.type === "listening" ? store.threadHasNewActivity[threadUid] ?? false : false,
            store.acknowledgeThreadActivity(threadUid),
        ];
    });
}

export function useCommentContent(commentUid: CommentUid) {
    return useFluxServices().useCommentStore((store) => {
        return store.type === "listening" ? store.commentContents[commentUid] : undefined;
    });
}

export function useOptionalCommentContent(commentUid: CommentUid | undefined) {
    return useFluxServices().useCommentStore((store) => {
        if (!commentUid) {
            return undefined;
        }

        return store.type === "listening" ? store.commentContents[commentUid] : undefined;
    });
}

export function useProjectChatHasNewActivity(): [boolean, () => void] {
    return useFluxServices().useCommentStore((store) => {
        const projectChatThreadUid = store.threadedMessages.namedThreadIndex.projectChat;
        if (store.type === "listening" && projectChatThreadUid) {
            return [
                store.threadHasNewActivity[projectChatThreadUid] ?? false,
                () => {
                    store.acknowledgeThreadActivity(projectChatThreadUid);
                },
            ];
        } else {
            return [false, () => {}];
        }
    });
}
