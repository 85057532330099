import {DocumentReducerError, ElementHelper, PcbTreeManager, setElementsAndGenerateNodes} from "@buildwithflux/core";
import {IDocumentData} from "@buildwithflux/models";

import {createActionRecordAction} from "../../../../helpers/actionCreator";
import {FluxLogger} from "../../../../modules/storage_engine/connectors/LogConnector";

type SubjectType = "elements" | "routes" | "nets";

export type PropertyRemovalMap = {[subjectUid: string]: {type: SubjectType; propertyKeysToRemove: string[]}};

export const removeSubjectProperties = createActionRecordAction(
    "removeSubjectProperties",
    (propertyRemovalMap: PropertyRemovalMap) => {
        return {
            payload: {
                propertyRemovalMap,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed properties from subject",
);

export function handleRemoveSubjectProperties(
    draftState: IDocumentData,
    action: ReturnType<typeof removeSubjectProperties>,
) {
    const {propertyRemovalMap} = action.payload;

    for (const [subjectUid, {type: subjectType, propertyKeysToRemove: removePropertyKeys}] of Object.entries(
        propertyRemovalMap,
    )) {
        const subjectProperties = draftState.elements[subjectUid]?.properties;
        const wasExcludedFromPcb = subjectProperties && ElementHelper.isExcludedFromPcb(subjectProperties);
        removePropertyKeys.forEach((propertyKey) => {
            if (subjectType === "elements" && draftState.elements) {
                if (propertyKey in draftState.elements[subjectUid]!.properties) {
                    delete draftState.elements[subjectUid]!.properties[propertyKey];
                } else {
                    // During batch-edit, properties are identified by their name,
                    // not uid. Delete properties by name instead. Make sure we
                    // delete *all* properties with the same name, because there
                    // may be hidden duplicates, which our code doesn't handle
                    // very well (and end up being even more confusing when they
                    // don't delete properly!)
                    const existingProperties = Object.values(draftState.elements[subjectUid]!.properties).filter(
                        (prop) => prop.name === propertyKey,
                    );
                    for (const property of existingProperties) {
                        delete draftState.elements[subjectUid]!.properties[property.uid];
                    }
                }
            } else if (subjectType === "routes" && draftState.routes) {
                delete draftState.routes[subjectUid]!.properties[propertyKey];
            } else if (subjectType === "nets" && draftState.nets) {
                delete draftState.nets[subjectUid]!.properties[propertyKey];
            }
        });

        // TODO: This block could be a good candidate of domain function
        // Start special case exclude_from_pcb element property
        const isExcludedFromPcb = ElementHelper.isExcludedFromPcb(draftState.elements[subjectUid]!.properties);
        const isNet = subjectType === "nets" && draftState.nets;
        if (wasExcludedFromPcb === true && isExcludedFromPcb === undefined) {
            if (subjectType === "elements") {
                if (!draftState.elements[subjectUid]) {
                    FluxLogger.captureError(
                        new DocumentReducerError(
                            action.type,
                            "Can't set excludeFromPcb to undefined (i.e. remove) since the element does not exist.",
                            action,
                        ),
                    );
                } else {
                    setElementsAndGenerateNodes(draftState, [draftState.elements[subjectUid]!]);
                }
            }
            // QUESTION: how to add only the nodes from the
            // subjectUid? or do we need this in case the element is
            // a layout or footprint?
            const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
            pcbDomTreeManager.updateNetNodes(draftState);
        } else if (isNet) {
            const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
            pcbDomTreeManager.updateNetNodes(draftState);
        }
    }
    // End special case exclude_from_pcb element property
}
