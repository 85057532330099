import {makeUserData} from "@buildwithflux/core";
import {CurrentUser, IUserData, IUserPrivateMetadata, UserChangeHandler, UserStateType} from "@buildwithflux/models";
import {UserRepository, SubscriptionManager, UserPrivateMetadataRepository} from "@buildwithflux/repositories";
import {Logger, Unsubscriber, wait} from "@buildwithflux/shared";
import {User as FirebaseUser} from "firebase/auth";
import {isEqual} from "lodash";

import {UserStorageHelper} from "../../storage_engine/helpers/UserStorageHelper";
import {CurrentUserService} from "../types";

import {AuthService} from "./auth";

abstract class AbstractFirebaseCurrentUserService implements CurrentUserService {
    public isSignUpInProgress = false;
    public isLogOutInProgress = false;

    protected firebaseUser: FirebaseUser | undefined = undefined;
    protected firebaseUserChangeUnsub: Unsubscriber | undefined;

    protected fluxUser: IUserData | undefined = undefined;
    protected fluxUserUnsub: Unsubscriber | undefined = undefined;

    protected fluxUserPrivateMetadata: IUserPrivateMetadata | undefined;
    protected fluxUserPrivateMetaChangeUnsub: Unsubscriber | undefined;

    protected anonymousSignInTimer: ReturnType<typeof setTimeout> | undefined;
    protected mostRecentAnonymousUser: FirebaseUser | undefined = undefined;

    protected readonly subscriptionMgrCurrentUser: SubscriptionManager<
        "currentUser",
        CurrentUser & {suggestedState?: UserStateType}
    > = new SubscriptionManager();
    protected readonly subscriptionMgrCurrentFirebaseUser: SubscriptionManager<
        "currentFirebaseUser",
        FirebaseUser | undefined
    > = new SubscriptionManager();

    /** @inheritDoc */
    public getCurrentUser(): IUserData | undefined {
        return this.fluxUser;
    }

    /** @inheritDoc */
    public subscribeToUserChanges(onUserChanged: UserChangeHandler): Unsubscriber {
        return this.subscriptionMgrCurrentUser.addSubscription("currentUser", onUserChanged);
    }

    /** @inheritDoc */
    public subscribeToFirebaseUserChanges(
        onFirebaseUserChanged: (user: FirebaseUser | undefined) => void,
    ): Unsubscriber {
        return this.subscriptionMgrCurrentFirebaseUser.addSubscription("currentFirebaseUser", onFirebaseUserChanged);
    }

    /** @inheritDoc */
    public getCurrentUserPrivateMetadata(): IUserPrivateMetadata | undefined {
        return this.fluxUserPrivateMetadata;
    }

    /** @inheritDoc */
    abstract logOut(): Promise<void>;

    /** @inheritDoc */
    abstract triggerResolve(): Promise<void>;

    /** @inheritDoc */
    abstract shutdown(): Promise<void>;

    /** @inheritDoc */
    abstract logInAnonymously(): Promise<void>;
}

export class FirebaseCurrentUserService extends AbstractFirebaseCurrentUserService {
    constructor(
        private readonly firebaseAuth: AuthService,
        private readonly users: UserRepository,
        private readonly userPrivateMeta: UserPrivateMetadataRepository,
        private readonly logger: Logger,
    ) {
        super();

        this.logger.debug("Initializing");
        this.firebaseAuth.enableLocalPersistence().then(() => {
            this.logger.debug("Enabled local persistence and subscribing to changes");
            firebaseAuth
                .subscribeToUserChanges((user) => this.onFirebaseAuthStateChanged(user))
                .then((unsub) => {
                    return (this.firebaseUserChangeUnsub = unsub);
                });
        });
    }

    /** @inheritDoc */
    override async triggerResolve(): Promise<void> {
        await this.resolveFluxUser();

        this.subscriptionMgrCurrentUser.notify("currentUser", {
            user: this.fluxUser,
            privateMetadata: this.fluxUserPrivateMetadata,
        });
    }

    /**
     * Create a timer that, when it expires, will sign in anonymously.  The timer protects against
     * races - when multiple tabs are open, the firebase auth service can have little blips where
     * the user becomes null.  When that happens, we don't want to immediately react to it by allowing
     * anonymous sign in.  So this timer will get canceled if the authentication loop "catches up" to
     * the timer before it expires.
     *
     * TODO: Separate service?
     */
    public async logInAnonymously(): Promise<void> {
        const firebaseUser = this.firebaseAuth.getCurrentFirebaseUser();

        if (this.anonymousSignInTimer || firebaseUser != null) {
            this.logger.debug("Skipping logInAnonymously", {
                anonymousSignInTimer: this.anonymousSignInTimer,
                firebaseUser,
            });

            return;
        }
        this.anonymousSignInTimer = setTimeout(() => {
            this.doLogInAnonymously();
            this.anonymousSignInTimer = undefined;
        }, 1000);
    }

    /** @inheritDoc */
    public async logOut(): Promise<void> {
        this.isLogOutInProgress = true;
        this.logger.debug("Starting logOutInProgress state");

        setTimeout(() => {
            this.isLogOutInProgress = false;
            this.logger.debug("Finished logOutInProgress state");
        }, 3_000);
        await this.clearFluxUserAndSubs();
        await this.firebaseAuth.logOutOfFirebase();
    }

    /** @inheritDoc */
    public async shutdown() {
        await this.clearFluxUserAndSubs();
    }

    private async doLogInAnonymously(): Promise<void> {
        if (this.firebaseUser || this.fluxUser || !!this.firebaseAuth.getCurrentFirebaseUser()) {
            this.logger.debug("Skipping doLogInAnonymously", {
                firebaseUser: this.firebaseUser,
                fluxUser: this.fluxUser,
                currentFirebaseUser: this.firebaseAuth.getCurrentFirebaseUser(),
            });
            return;
        }

        this.logger.debug("Logging in anonymously");

        const creds = await this.firebaseAuth.createAnonymousFirebaseUser();
        const {handle, displayName} = UserStorageHelper.generateAnonUserName();
        const newUserData = makeUserData({
            uid: creds.user.uid,
            handle: handle.toLowerCase(),
            email: creds.user.email ?? undefined,
            full_name: displayName,
            picture: "",
            isAnonymous: true,
        });

        await this.users.save(newUserData);

        this.firebaseUser = creds.user ?? undefined;
        this.fluxUser = newUserData;
        this.fluxUserPrivateMetadata = undefined;

        this.subscriptionMgrCurrentFirebaseUser.notify("currentFirebaseUser", creds.user ?? undefined);
        this.subscriptionMgrCurrentUser.notify("currentUser", {
            user: newUserData,
            privateMetadata: undefined,
        });
    }

    private onFirebaseAuthStateChanged(user: FirebaseUser | null) {
        this.logger.debug("Received firebase auth state change", {user});

        // If the firebase current user is non-null, but we got a null firebase user in an
        // event, then firebase is in a transient state and we should ignore the update.
        if (user == null && this.firebaseAuth.getCurrentFirebaseUser() != null) {
            return;
        }

        if (user !== null && this.anonymousSignInTimer) {
            clearTimeout(this.anonymousSignInTimer);
            this.anonymousSignInTimer = undefined;
        }

        this.firebaseUser = user ?? undefined;
        this.fluxUserPrivateMetadata = undefined;
        this.subscriptionMgrCurrentFirebaseUser.notify("currentFirebaseUser", this.firebaseUser);

        this.resolveFluxUser().then((_user) => {
            this.subscriptionMgrCurrentUser.notify("currentUser", {
                user: this.fluxUser,
                privateMetadata: this.fluxUserPrivateMetadata,
            });
        });
    }

    /**
     * Watch for changes that happen to the flux user.  Note that the user might not exist yet!  For example,
     * if a user creates a new account, there is a brief moment where their firebase user becomes non-anonymous,
     * but their flux user doesn't exist yet.
     *
     * TODO: Move login/register logic here so that we can avoid some of this ~mild~ WILD unpleasantness.
     */
    private watchFluxUser(firebaseUser: Readonly<FirebaseUser>): void {
        if (firebaseUser.isAnonymous) {
            this.mostRecentAnonymousUser = firebaseUser;
        }

        if (!this.fluxUserUnsub) {
            this.logger.debug("Watching flux user", {firebaseUser});
            this.fluxUserUnsub = this.users.subscribeToUser(firebaseUser.uid, (userChange) => {
                switch (userChange.type) {
                    case "updated":
                    case "created": {
                        void this.onFluxUserUpdate(userChange.type, userChange.data);
                        break;
                    }
                    case "deleted": {
                        const currentUserState = this.firebaseAuth.getCurrentFirebaseUser();

                        /*
                         * When we upgrade an existing anonymous user to a non-anonymous user, we create their new profile, and
                         * delete their old profile, in a transaction. This callback will then trigger. However, it's not a normal
                         * delete and the "created" trigger here will also fire.
                         *
                         * We should ignore such deletes. We do this by comparing the state of the user now to the most recent
                         * anonymous user.
                         */
                        if (
                            currentUserState &&
                            this.mostRecentAnonymousUser &&
                            currentUserState.uid === this.mostRecentAnonymousUser.uid &&
                            !currentUserState.isAnonymous
                        ) {
                            // Do nothing - it's probably a sign up
                            this.logger.debug("Doing nothing on deleted flux user - expected from transaction");
                        } else {
                            // Real user profile delete detected
                            void this.onFluxUserChanged("deleted");
                        }

                        // Either way, we only need this logic once, and must clear the flag so that we don't miss any "real" deletes
                        this.mostRecentAnonymousUser = undefined;
                        break;
                    }
                }
            });
        }
    }

    /**
     * If the user is deleted, then we shut down all subscriptions.
     */
    private async onFluxUserDeleted(): Promise<void> {
        this.logger.debug("Received flux user delete");

        await this.clearFluxUserAndSubs();

        this.subscriptionMgrCurrentUser.notify("currentUser", {
            user: this.fluxUser,
            privateMetadata: this.fluxUserPrivateMetadata,
        });
    }

    /**
     * If the user is updated, we check for the latest private metadata and then broadcast the update.
     */
    private async onFluxUserUpdate(changeType: "updated" | "created", fluxUser: Readonly<IUserData>): Promise<void> {
        this.logger.debug("Received flux user update", {fluxUser});
        let privateMetadata: IUserPrivateMetadata | undefined;
        try {
            privateMetadata = await this.userPrivateMeta.getForUser(fluxUser.uid);
        } catch (error) {
            privateMetadata = await this.userPrivateMeta.touch(fluxUser.uid);
        }
        // QUESTION: Shouldn't we be watching this?
        // if (changeType === "created" && fluxUser && !fluxUser.isAnonymous) {
        //     this.watchFluxUserPrivateMetadata(fluxUser);
        // }
        if (!isEqual(privateMetadata, this.fluxUserPrivateMetadata) || !isEqual(fluxUser, this.fluxUser)) {
            this.fluxUser = fluxUser;
            this.fluxUserPrivateMetadata = privateMetadata;

            this.subscriptionMgrCurrentUser.notify("currentUser", {
                user: this.fluxUser,
                privateMetadata: this.fluxUserPrivateMetadata,
            });
        }
    }

    private async onFluxUserChanged(changeType: "updated" | "created", fluxUser: IUserData): Promise<void>;
    private async onFluxUserChanged(changeType: "deleted"): Promise<void>;
    private async onFluxUserChanged(
        changeType: "updated" | "created" | "deleted",
        fluxUser?: IUserData,
    ): Promise<void> {
        if (changeType === "deleted") {
            if (fluxUser) throw new Error("unexpected state");
            return this.onFluxUserDeleted();
        } else {
            if (!fluxUser) throw new Error("unexpected state");
            return this.onFluxUserUpdate(changeType, fluxUser);
        }
    }

    private async onFluxUserPrivateMetadataChanged(
        changeType: "updated" | "created",
        privateMetadata: IUserPrivateMetadata,
    ): Promise<void>;
    private async onFluxUserPrivateMetadataChanged(changeType: "deleted"): Promise<void>;
    private async onFluxUserPrivateMetadataChanged(
        changeType: "updated" | "created" | "deleted",
        privateMetadata?: IUserPrivateMetadata,
    ): Promise<void> {
        // TODO: what do we do if we get this update with no flux user?
        if (!isEqual(privateMetadata, this.fluxUserPrivateMetadata)) {
            this.fluxUserPrivateMetadata = privateMetadata;
            this.subscriptionMgrCurrentUser.notify("currentUser", {
                user: this.fluxUser,
                privateMetadata: this.fluxUserPrivateMetadata,
            });
        }
    }

    private watchFluxUserPrivateMetadata(fluxUser: Readonly<IUserData>): void {
        if (!this.fluxUserPrivateMetaChangeUnsub) {
            this.fluxUserPrivateMetaChangeUnsub = this.userPrivateMeta.subscribeForUser(fluxUser.uid, (upmChange) => {
                switch (upmChange.type) {
                    case "updated":
                    case "created": {
                        void this.onFluxUserPrivateMetadataChanged(upmChange.type, upmChange.data);
                        break;
                    }
                    case "deleted": {
                        void this.onFluxUserPrivateMetadataChanged(upmChange.type);
                        break;
                    }
                }
            });
        }
    }

    private async resolveFluxUser(): Promise<IUserData | undefined> {
        this.logger.debug("Resolving Flux User for Firebase user");

        const fluxUser = this.firebaseUser ? await this.users.getByUid(this.firebaseUser.uid) : undefined;
        this.fluxUser = fluxUser;

        if (!this.fluxUser && this.firebaseUser && !this.firebaseUser.isAnonymous && !this.isSignUpInProgress) {
            // Partially authenticated, or during sign up already
            this.logger.debug("Found partially authenticated scenario");
            this.subscriptionMgrCurrentUser.notify("currentUser", {
                user: undefined,
                privateMetadata: undefined,
                suggestedState: UserStateType.enum.partiallyAuthenticated,
            });
        }

        // If the firebase user exist, then start a loop to watch for changes to the user's data.
        if (!this.fluxUserUnsub && this.firebaseUser) {
            this.watchFluxUser(this.firebaseUser);
        }

        // If the flux user exists and is non-anonymous, start watching their private metadata.
        if (this.fluxUser && !this.fluxUser.isAnonymous) {
            let privateMetadata: IUserPrivateMetadata | undefined;
            try {
                privateMetadata = await this.userPrivateMeta.getForUser(this.fluxUser.uid);
            } catch (error) {
                privateMetadata = await this.userPrivateMeta.touch(this.fluxUser.uid);
            }
            if (privateMetadata) {
                this.fluxUserPrivateMetadata = privateMetadata;
            }
            this.watchFluxUserPrivateMetadata(this.fluxUser);
        }

        return this.fluxUser;
    }

    /**
     * @todo hook this into subscription error handling.
     */
    private async clearFluxUserAndSubs(): Promise<void> {
        if (this.fluxUserPrivateMetaChangeUnsub) this.fluxUserPrivateMetaChangeUnsub();
        if (this.fluxUserUnsub) this.fluxUserUnsub();

        this.fluxUser = undefined;
        this.fluxUserPrivateMetadata = undefined;
    }
}

export class MockCurrentUserService extends AbstractFirebaseCurrentUserService {
    constructor(currentUser: IUserData | undefined, userPrivateMetadata?: IUserPrivateMetadata) {
        super();
        this.fluxUser = currentUser;
        this.fluxUserPrivateMetadata = userPrivateMetadata;
    }

    /**
     * Set the current user and notify any downstream subscribers
     *
     * This method is only for use as a testing utility, and is not present on the FirebaseCurrentUserService implementation
     */
    public simulateCurrentUserChange(user: IUserData): void {
        this.fluxUser = user;
        this.triggerResolve();
    }

    /**
     * Set the current user's private metadata and notify any downstream subscribers
     *
     * This method is only for use as a testing utility, and is not present on the FirebaseCurrentUserService implementation
     */
    public simulatePrivateMetadataChange(privateMetadata: IUserPrivateMetadata) {
        this.fluxUserPrivateMetadata = privateMetadata;

        this.subscriptionMgrCurrentUser.notify("currentUser", {
            user: this.fluxUser,
            privateMetadata: this.fluxUserPrivateMetadata,
        });
    }

    /** @inheritDoc */
    public override getCurrentUserPrivateMetadata(): IUserPrivateMetadata | undefined {
        if (this.fluxUserPrivateMetadata) {
            return this.fluxUserPrivateMetadata;
        } else if (this.fluxUser) {
            return {
                uid: this.fluxUser.uid,
            };
        }
    }

    /** @inheritDoc */
    public override subscribeToUserChanges(onUserChanged: UserChangeHandler): Unsubscriber {
        const unsubscriber = super.subscribeToUserChanges(onUserChanged);

        if (this.fluxUser) {
            setTimeout(() => {
                this.subscriptionMgrCurrentUser.notify("currentUser", {
                    user: this.fluxUser,
                    privateMetadata: this.fluxUserPrivateMetadata,
                });
            }, 0);
        }

        return unsubscriber;
    }

    /** @inheritDoc */
    public logInAnonymously(): Promise<void> {
        return new Promise((resolve) => resolve());
    }

    /** @inheritDoc */
    public logOut(): Promise<void> {
        return new Promise((resolve) => {
            this.fluxUser = undefined;
            this.fluxUserPrivateMetadata = undefined;

            /*
             * The real implementation calls await this.firebaseAuth.logOutOfFirebase(); at this point which, in turn,
             * calls its private method this.onFirebaseAuthStateChanged(null), which eventually notifies subscribers
             * with undefined. We do that here manually.
             */
            this.subscriptionMgrCurrentUser.notify("currentUser", {
                user: this.fluxUser,
                privateMetadata: this.fluxUserPrivateMetadata,
            });

            resolve();
        });
    }

    /** @inheritDoc */
    override async triggerResolve(): Promise<void> {
        await wait(50);

        this.subscriptionMgrCurrentUser.notify("currentUser", {
            user: this.fluxUser,
            privateMetadata: this.fluxUserPrivateMetadata,
        });
    }

    /** @inheritDoc */
    public shutdown(): Promise<void> {
        return Promise.resolve();
    }
}
