import {
    applyDesignatorMappingToElement,
    DesignatorMapping,
    remapDesignatorsToAvoidDuplicates,
} from "@buildwithflux/core";
import {IDocumentData, IElementData, IPartVersionData} from "@buildwithflux/models";
import {cloneDeep, isEqual} from "lodash";

/**
 * This function is a helper method for the document reducer
 *
 * @param draftState - document state draft
 * @param subjectUids - array of uids of subjects that we wanna unselect, please note subjects here
 * could be elements/routes/pcbLayoutNodes
 *
 * TODO: Move this to core
 */
export const removeSubjectsFromSelection = (draftState: IDocumentData, subjectUids: string[]) => {
    if (draftState.selectedObjectUids) {
        // QUESTION: avoid filter for better memoization?
        const subjectUidsSet = new Set(subjectUids);
        draftState.selectedObjectUids = draftState.selectedObjectUids.filter((uid) => !subjectUidsSet.has(uid));
    }
};

/**
 * This function is a helper for updating `draftState` selection
 *
 * @param draftState
 * @param selectedObjectUids
 */
export const updateSelectedSubjects = (draftState: IDocumentData, selectedObjectUids: string[]) => {
    if (draftState && draftState.selectedObjectUids?.length !== selectedObjectUids.length) {
        // If length is different, we should definitely update the `selectedObjectUids`
        draftState.selectedObjectUids = selectedObjectUids;
    } else if (draftState && selectedObjectUids) {
        // Otherwise, we should do a deep compare. Separating these two cases can
        // avoid this `isEqual` check when it doesn't need to
        // TODO I'm surprised lodash.isEqual doesn't do a length check as one of its
        // first checks! Meaning the savings by doing this is neglible. (Looking at the
        // source, it seems to, but I'm not absolutely certain without some further
        // thought so I'll leave this as-is for now)
        if (!isEqual(draftState.selectedObjectUids, selectedObjectUids)) {
            draftState.selectedObjectUids = selectedObjectUids;
        }
    }
};

/**
 * Helper function to update an element to use a new part version.
 * Includes designator remapping, if there are conflicts.
 */
export function updateElementToUsePartVersion(params: {
    element: IElementData;
    partVersionData: IPartVersionData;
    otherDesignatorsAlreadyInProject: string[];
}) {
    // NOTE: very important to clone this object. Easy to forget and make weird bugs!!!
    params.element.part_version_data_cache = cloneDeep(params.partVersionData);

    // If the element was already remapped before, apply that mapping again
    const previousMapping = params.element.designatorMappings
        ? new DesignatorMapping(params.element.designatorMappings)
        : undefined;
    if (previousMapping) {
        applyDesignatorMappingToElement({
            element: params.element,
            mapping: previousMapping,
        });
        // Note: there may be designator conflicts *within* the part now, i.e. if *new* elements were added
        // in the latest part version. This should be handled in remapDesignators below.
    }

    // Remap any designators that need to change to avoid conflicts in host project
    remapDesignatorsToAvoidDuplicates({
        elementToRemap: params.element,
        designatorsToAvoid: params.otherDesignatorsAlreadyInProject,
    });
}
