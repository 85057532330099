import {createTheme} from "@material-ui/core/styles";
import React from "react";

import {getThemeConfig} from "../../../resources/Theme";

function useTheme() {
    return React.useMemo(() => {
        // NOTE: this is just always true, we don't give users theme options yet
        const themeConfig = getThemeConfig(true);

        return createTheme(themeConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export default useTheme;
